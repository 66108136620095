import logo from './logo.svg';
import phone from "./assets/images/home-app-10.png"
import './App.css';
import {ConfigProvider, theme} from "antd";
import AppRouter from "./components/Router/AppRouter";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {changeHeight, changeScreenSize, changeWidth, setLanguage, setTheme} from "./store/reducers/deviceInfoSlice";
import {tryConnect} from "./store/reducers/Actions";

function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    const {darkTheme} = useSelector(state => state.deviceInfoSlice)

    const myTheme = darkTheme ? {
        // Конфигурация для темного режима
        algorithm: theme.darkAlgorithm,
        token: {
            colorPrimary: '#b90000',
        },
    } : {
        // Конфигурация для светлого режима
        token: {
            colorPrimary: '#b90000',
        },
    }

    function getDataLocalStorage() {
        let currentLanguage = localStorage.getItem('lang')
        let currentTheme = localStorage.getItem('theme')
        if (currentLanguage) {
           dispatch(setLanguage(currentLanguage))
        }
        if (currentTheme) {
            dispatch(setTheme(currentTheme))
        }

    }

    useEffect(() => {
        getDataLocalStorage()
        const handleResize = () => {
            const isMobileSize = window.matchMedia('(max-width: 768px)').matches;
            // setIsMobile(isMobileSize);
            dispatch(changeScreenSize(isMobileSize))
            dispatch(changeWidth())
            dispatch(changeHeight())
        };

        dispatch(tryConnect())

        // Обработчик изменения размера экрана
        window.addEventListener('resize', handleResize);

        // Вызовем обработчик при первоначальной загрузке компонента
        handleResize();

        // Очистка обработчика при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ConfigProvider theme={myTheme}>
            <AppRouter/>
        </ConfigProvider>
    );
}

export default App;
