//
// import React from 'react';
// import {MTLModel} from 'react-3d-viewer'
// import scenePath from "../../assets/glb/Project Name.obj";
// import mtl from "../../assets/glb/Project Name.mtl"
//
// function GLBViewer() {
//     return (
//         <div>
//             <MTLModel
//                 enableZoom={true}
//                 width="1400" height="1400"
//                 position={{x: 10, y:10, z:10}}
//                 src={scenePath}
//                 mtl={mtl}
//             />
//         </div>
//     );
// }
//
// export default GLBViewer;


import React, {Suspense} from 'react';
import {Canvas} from '@react-three/fiber';
import {Environment, OrbitControls} from '@react-three/drei';
import {Model} from "../../assets/Nova";
import {Modeler} from "../../assets/Iphone";
import {ModelPixel} from "../../assets/Pixel";

export default function GLBViewer() {

    return (
        <>
            {/*<div style={{height: 1000}}>*/}
            {/*    <Canvas camera={{ fov: 18 }} >*/}
            {/*        <ambientLight intensity={1.25} />*/}
            {/*        <Suspense fallback={null}>*/}
            {/*            <Model />*/}
            {/*        </Suspense>*/}
            {/*        <Environment preset="sunset" />*/}
            {/*        <OrbitControls />*/}
            {/*    </Canvas>*/}
            {/*</div>*/}
            {/*<Canvas camera={{fov: 18}}>*/}
            {/*    <ambientLight intensity={1.25}/>*/}
            {/*    <Modeler/>*/}
            {/*    <Environment preset="warehouse"/>*/}
            {/*    /!*<directionalLight />*!/*/}
            {/*    <OrbitControls enablePan={false}*/}
            {/*                   enableZoom={false} // Отключение приближения/отдаления с помощью колесика мыши*/}
            {/*                   // enableRotate={false} // Отключение вращения камеры*/}
            {/*    />*/}
            {/*</Canvas>*/}
            <Canvas camera={{fov: 18}}>
                <ambientLight intensity={1.25}/>
                <ModelPixel />
                <Environment preset="warehouse"/>
                {/*<directionalLight />*/}
                <OrbitControls enablePan={false}
                               enableZoom={false} // Отключение приближения/отдаления с помощью колесика мыши
                    // enableRotate={false} // Отключение вращения камеры
                />
            </Canvas>
        </>
    );
}