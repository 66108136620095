import {useDispatch, useSelector} from "react-redux";
import {motion} from "framer-motion";
import React from "react";
import {Avatar, Button, Card, Rate, Statistic, Typography} from "antd";
import {ArrowDownOutlined, CheckCircleOutlined, LikeOutlined} from "@ant-design/icons";
import styles from "./appClientsComponent.module.css"
import {Waypoint} from "react-waypoint";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";

// Import photo
import locitane from "../../assets/images/logosDistributers/locitane.png"
import bakuDist from "../../assets/images/logosDistributers/baku_distribution.8b49993d17a259a56edb.png"
import cannakale from "../../assets/images/logosDistributers/canakkale.7c7911fa27c6aba4bd47.png"
import euroClima from "../../assets/images/logosDistributers/eoruclima.c88a0e1d18c656070d37.png"
import brgp from "../../assets/images/logosDistributers/brpg.f26b7aea09b8f38d04b3.png"
import masterService from "../../assets/images/logosDistributers/originalMartinez.png"
import originalMartinez from "../../assets/images/logosDistributers/locitane.png"
import unimetal from "../../assets/images/logosDistributers/unimetal.4baaabe0523d70fc29dc.png"
import vienGroup from "../../assets/images/logosDistributers/Vien_group.0e36108630d720889f15.jpg"

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

import "./style.css"

// import required modules
import { EffectCards, Autoplay, EffectFlip } from 'swiper/modules';
import Meta from "antd/es/card/Meta";

const AppClientsComponent = () => {
    const dispatch = useDispatch();
    const {isMobile, darkTheme, width, height, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)
    const {Title, Paragraph, Text, Link} = Typography;

    const changeNav = (args) => {
        dispatch(changeNavigationLink(["Clients"]))
        // console.log('Вошли на компонент', args);
    };

    return (
        <div id={"Clients"} style={isMobile ? {
            minHeight: height + 64,
            display: "flex",
            alignItems: "center",
            width: width,
            flexWrap: "wrap",
            justifyContent: "center",
            flexDirection: "column-reverse"
        } : {
            minHeight: height + 64,
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center"
        }}>
            <div style={isMobile ? {
                display: "flex",
                width: width * 0.8,
                height: height * 0.8,
                flexDirection: "row-reverse"
            } : {
                display: "flex",
                width: "50%",
                height: "100%",
                alignItems: "center"
            }}>
                <motion.div
                    initial={{y: 100, opacity: 0}} // начальное состояние компонента (смещение вниз и прозрачность 0)
                    whileInView={{y: 0, opacity: 1}} // конечное состояние компонента (нет смещения и полная прозрачность)
                    transition={{duration: 1}} // длительность анимации
                    viewport={{ once: false }}
                    style={isMobile ? {
                        display: "flex",
                        width: "100%",
                        height: "100%"
                    } : {
                        display: "flex",
                        width: "75%",
                        height: "75%"
                    }}
                >
                    <Swiper
                        effect={isMobile ? 'flip' : 'cards'}
                        grabCursor={true}
                        modules={[EffectCards, EffectFlip, Autoplay]}
                        loop={true}
                        className="mySwiperPricing"
                        initialSlide={4}
                    >
                        <SwiperSlide  style={{display:"flex", justifyContent: "center" ,alignItems: "center", backgroundColor: "transparent"}}><Card bordered={false} title={<Rate style={{fontSize: 12}} disabled defaultValue={5} />} style={isMobile ? {textAlign: "left" ,width: "80%", minHeight: "60%", borderRadius: 30,padding:20, backgroundColor: "#404040"} : {textAlign: "left" ,maxWidth: "80%", height: "60%", borderRadius: 30,padding:20, backgroundColor: "#404040"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%"}}>
                                <Text style={{fontFamily: appFont, color: "white"}}>{appTextData.AppClients.feedbacks.locitane.response}</Text>
                                <Meta
                                    avatar={<Avatar src={locitane} />}
                                    title={<Text style={{color: "white", fontSize: 16}}>Locitane</Text>}
                                    description="This is the description"
                                    style={isMobile ? {} : { margin: "20px 0" }}
                                />
                            </div>
                        </Card></SwiperSlide>
                        <SwiperSlide  style={{display:"flex", justifyContent: "center" ,alignItems: "center", backgroundColor: "transparent"}}><Card bordered={false} title={<Rate style={{fontSize: 12}} disabled defaultValue={5} />} style={isMobile ? {textAlign: "left" ,width: "80%", minHeight: "60%", borderRadius: 30,padding:20, backgroundColor: "rgb(131,127,127)"} : {textAlign: "left" ,maxWidth: "80%", height: "60%", borderRadius: 30,padding:20, backgroundColor: "rgb(131,127,127)"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%"}}>
                                <Text style={{fontFamily: appFont, color: "white"}}>{appTextData.AppClients.feedbacks.canakkale.response}</Text>
                                <Meta
                                    avatar={<Avatar src={cannakale} />}
                                    title={<Text style={{color: "white", fontSize: 16}}>Canakkale MMC</Text>}
                                    description="This is the description"
                                    style={isMobile ? {} : { margin: "20px 0" }}
                                />
                            </div>
                        </Card></SwiperSlide>
                        <SwiperSlide  style={{display:"flex", justifyContent: "center" ,alignItems: "center", backgroundColor: "transparent"}}><Card bordered={false} title={<Rate style={{fontSize: 12}} disabled defaultValue={5} />} style={isMobile ? {textAlign: "left" ,width: "80%", minHeight: "60%", borderRadius: 30,padding:20, backgroundColor: "#404040"} : {textAlign: "left" ,maxWidth: "80%", height: "60%", borderRadius: 30,padding:20, backgroundColor: "#404040"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%"}}>
                                <Text style={{fontFamily: appFont, color: "white"}}>{appTextData.AppClients.feedbacks.bakuDist.response}</Text>
                                <Meta
                                    avatar={<Avatar src={bakuDist} />}
                                    title={<Text style={{color: "white", fontSize: 16}}>Baku Dist</Text>}
                                    description="This is the description"
                                    style={{ margin: "20px 0"}}
                                />
                            </div>
                        </Card></SwiperSlide>
                        <SwiperSlide  style={{display:"flex", justifyContent: "center" ,alignItems: "center", backgroundColor: "transparent"}}><Card bordered={false} title={<Rate style={{fontSize: 12}} disabled defaultValue={5} />} style={isMobile ? {textAlign: "left" ,width: "80%", minHeight: "60%", borderRadius: 30,padding:20, backgroundColor: "rgb(131,127,127)"} : {textAlign: "left" ,maxWidth: "80%", height: "60%", borderRadius: 30,padding:20, backgroundColor: "rgb(131,127,127)"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%"}}>
                                <Text style={{fontFamily: appFont, color: "white"}}>{appTextData.AppClients.feedbacks.brgp.response}</Text>
                                <Meta
                                    avatar={<Avatar src={brgp} />}
                                    title={<Text style={{color: "white", fontSize: 16}}>BRGP</Text>}
                                    description="This is the description"
                                    style={isMobile ? {} : { margin: "20px 0" }}
                                />
                            </div>
                        </Card></SwiperSlide>
                        <SwiperSlide  style={{display:"flex", justifyContent: "center" ,alignItems: "center", backgroundColor: "transparent"}}><Card bordered={false} title={<Rate style={{fontSize: 12}} disabled defaultValue={5} />} style={isMobile ? {textAlign: "left" ,width: "80%", minHeight: "60%", borderRadius: 30,padding:20, backgroundColor: "#404040"} : {textAlign: "left" ,maxWidth: "80%", height: "60%", borderRadius: 30,padding:20, backgroundColor: "#404040"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%"}}>
                                <Text style={{fontFamily: appFont, color: "white"}}>{appTextData.AppClients.feedbacks.euroClima.response}</Text>
                                <Meta
                                    avatar={<Avatar src={euroClima} />}
                                    title={<Text style={{color: "white", fontSize: 16}}>EuroClima</Text>}
                                    description="This is the description"
                                    style={isMobile ? {} : { margin: "20px 0" }}
                                />
                            </div>
                        </Card></SwiperSlide>
                        <SwiperSlide  style={{display:"flex", justifyContent: "center" ,alignItems: "center", backgroundColor: "transparent"}}><Card bordered={false} title={<Rate style={{fontSize: 12}} disabled defaultValue={5} />} style={isMobile ? {textAlign: "left" ,width: "80%", minHeight: "60%", borderRadius: 30,padding:20, backgroundColor: "rgb(131,127,127)"} : {textAlign: "left" ,maxWidth: "80%", height: "60%", borderRadius: 30,padding:20, backgroundColor: "rgb(131,127,127)"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%"}}>
                                <Text style={{fontFamily: appFont, color: "white"}}>{appTextData.AppClients.feedbacks.vienGroup.response}</Text>
                                <Meta
                                    avatar={<Avatar src={vienGroup} />}
                                    title={<Text style={{color: "white", fontSize: 16}}>VienGroup</Text>}
                                    description="This is the description"
                                    style={isMobile ? {} : { margin: "20px 0" }}
                                />
                            </div>
                        </Card></SwiperSlide>
                        <SwiperSlide  style={{display:"flex", justifyContent: "center" ,alignItems: "center", backgroundColor: "transparent"}}><Card bordered={false} title={<Rate style={{fontSize: 12}} disabled defaultValue={5} />} style={isMobile ? {textAlign: "left" ,width: "80%", minHeight: "60%", borderRadius: 30,padding:20, backgroundColor: "#404040"} : {textAlign: "left" ,maxWidth: "80%", height: "60%", borderRadius: 30,padding:20, backgroundColor: "#404040"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%"}}>
                                <Text style={{fontFamily: appFont, color: "white"}}>{appTextData.AppClients.feedbacks.masterService.response}</Text>
                                <Meta
                                    avatar={<Avatar src={masterService} />}
                                    title={<Text style={{color: "white", fontSize: 16}}>Master Service</Text>}
                                    description="This is the description"
                                    style={isMobile ? {} : { margin: "20px 0" }}
                                />
                            </div>
                        </Card></SwiperSlide>
                        <SwiperSlide  style={{display:"flex", justifyContent: "center" ,alignItems: "center", backgroundColor: "transparent"}}><Card bordered={false} title={<Rate style={{fontSize: 12}} disabled defaultValue={5} />} style={isMobile ? {textAlign: "left" ,width: "80%", minHeight: "60%", borderRadius: 30,padding:20, backgroundColor: "rgb(131,127,127)"} : {textAlign: "left" ,maxWidth: "80%", height: "60%", borderRadius: 30,padding:20, backgroundColor: "rgb(131,127,127)"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%"}}>
                                <Text style={{fontFamily: appFont, color: "white"}}>{appTextData.AppClients.feedbacks.uniMetal.response}</Text>
                                <Meta
                                    avatar={<Avatar src={unimetal} />}
                                    title={<Text style={{color: "white", fontSize: 16}}>Unimetal</Text>}
                                    description="This is the description"
                                    style={isMobile ? {} : { margin: "20px 0" }}
                                />
                            </div>
                        </Card></SwiperSlide>
                    </Swiper>
                </motion.div>
            </div>
            <Waypoint onEnter={changeNav}
                // onLeave={handleLeave}
            >
                <div style={isMobile ? {
                    display: "flex",
                    width: "80%",
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    paddingTop: 64
                } : {
                    display: "flex",
                    width: "50%",
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "center"
                }}>
                    <div>
                        <Text className={styles.helpText}>{appTextData.AppClients.clients}</Text>
                    </div>
                    <div style={{textAlign: "right"}}>
                        <Text style={{fontSize: 38, fontWeight: 600, fontFamily: appFont}}>{appTextData.AppClients.title1}<Text
                            style={{fontSize: 38, fontWeight: 600, color: "#ff0000", fontFamily: appFont}}>{appTextData.AppClients.title2}</Text>!</Text>
                    </div>
                    <div style={{marginTop: 10, marginBottom: 10, textAlign: "right"}}>
                        <Text style={{fontFamily: appFont}}>{appTextData.AppClients.titleText}</Text>
                    </div>
                    <div style={{marginTop: 10, marginBottom: 20, display: "flex"}}>
                        <Statistic title={appTextData.AppClients.installed} value={appTextData.AppClients.installedText} style={{marginRight: 40}} prefix={<ArrowDownOutlined style={{color: "red"}}/>}/>
                        <Statistic title={appTextData.AppClients.feedback} value={"100+"} prefix={<LikeOutlined style={{color: "red"}}/>} />
                    </div>
                </div>
            </Waypoint>
        </div>
    )
}

export default AppClientsComponent