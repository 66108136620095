import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from 'axios'

// For trying to connect server with ild session
export const tryConnect = createAsyncThunk('api/tryConnect', async () => {
    try {
        const response =  await axios.get(`https://antinovba.az/nfc/try_connect/`, {
            withCredentials: true,
        });
        if (response.data.session === 'active') {
        } else {
            console.warn('Please login again');
        }
        console.log(response.data)
        return response.data
    } catch (error) {
        console.warn(`${error.message}`)
        return {session: "false"}
        // throw new Error(error.message);
    }
});

// For login user
export const loginApi = createAsyncThunk('api/login', async ({username, password}) => {
    try {
        const response =  await axios.post(`https://antinovba.az/nfc/login_page/`, {
            username: username,
            password: password
        }, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
        })
        if (response.data.session === 'success') {
        } else {
            console.warn('Please login again');
        }
        return response.data
    } catch (error) {
        console.warn(`${error.message}`)
        return {session: "false"}
        // throw new Error(error.message);
    }
});