/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 .\src\assets\glb\nova.glb 
Author: castte (https://sketchfab.com/castte)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/nova-8f6de4fe021f4ff1a3179240be33ff5f
Title: Nova
*/

import React, { useRef } from 'react'
import gleb from "./glb/nova.glb"
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF(gleb)
  return (
    <group {...props} dispose={null} position={[0, -1, 0]}>
      <mesh geometry={nodes.eyeshadow_2_0.geometry} material={materials.eyeshadow} />
      <mesh geometry={nodes.Eyes_Cornea_4_0.geometry} material={materials.Eyes_Cornea} />
      <mesh geometry={nodes.Eyes_6_0.geometry} material={materials.Eyes} />
      <mesh geometry={nodes.Eyelashes_23_0.geometry} material={materials.Eyelashes} scale={10} />
      <mesh geometry={nodes.Eyebrows_29_0.geometry} material={materials.Eyebrows} scale={10} />
      <mesh geometry={nodes['24_bod_1_0_0_006_11_0'].geometry} material={materials['24_bod_1_0_0_006']} />
      <mesh geometry={nodes['24_bod_1_0_0_003_13_0'].geometry} material={materials['24_bod_1_0_0_003']} />
      <mesh geometry={nodes['24_bod_1_0_0_15_0'].geometry} material={materials['24_bod_1_0_0']} />
      <mesh geometry={nodes['27_hair_0_2_0_0_001_17_0'].geometry} material={materials['27_hair_0_2_0_0_001']} />
      <mesh geometry={nodes.Data_Dva_Body_Ellowas_19_0.geometry} material={materials.Data_Dva_Body_Ellowas} />
      <mesh geometry={nodes.Widowmaker_mouth_21_0.geometry} material={materials.Widowmaker_mouth} />
      <mesh geometry={nodes.Data__Phys_Boobs_25_0.geometry} material={materials.Data__Phys_Boobs} />
      <mesh geometry={nodes.Data__Phys_Boobs_001_27_0.geometry} material={materials.Data__Phys_Boobs_001} />
    </group>
  )
}

useGLTF.preload(gleb)
