import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isCaptchaShow: false,
    isCaptchaError: null,
    isCaptchaSuccess: false,
    product: "",
    userType: ""
};

const contactSlice = createSlice({
    name: 'contactSlice',
    initialState: initialState,
    reducers: {
        changeCaptchaVisibility: (state) => {
            state.isCaptchaShow = !state.isCaptchaShow
        },
        changeCaptchaSuccess: (state, action) => {
            state.isCaptchaSuccess = true
            state.isCaptchaError = false
            state.isCaptchaShow = false
        },
        changeCaptchaError: (state, action) => {
            state.isCaptchaSuccess = false
            state.isCaptchaError = true
            state.isCaptchaShow = true
        },
        changeProduct: (state, action) => {
            state.product = action.payload
        },
        changeUserType: (state, action) => {
            state.userType = action.payload
        },
    }
});

export const { changeCaptchaVisibility, changeCaptchaSuccess, changeCaptchaError, changeProduct, changeUserType } = contactSlice.actions;
export default contactSlice.reducer