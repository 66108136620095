import styles from "./dashboard.module.css"
import AppHomeComponent from "../AppHomeComponent/AppHomeComponent";
import React, {useState} from "react";
import {Content} from "antd/es/layout/layout";
import {useDispatch, useSelector} from "react-redux";
import {Button, Layout, Menu, Result, theme, Typography} from "antd";
import Sider from "antd/es/layout/Sider";
import {
    AppstoreOutlined,
    BarChartOutlined, CalendarOutlined,
    CloudOutlined, LoginOutlined, ShopOutlined, TeamOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";
import Profile from "./Components/Profile/Profile";
import Subscribe from "./Components/Subscribe/Subscribe";
import {changeLoginModalOpen} from "../../store/reducers/userSlice";

const Dashboard = () => {
    const {isMobile, darkTheme, width, height, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)
    const {session, username, loginModalOpen} = useSelector(state => state.userSlice)
    const {Title, Text} = Typography
    const [currentPage, setCurrentPage] = useState("User")
    const dispatch = useDispatch()

    const items = [
        {
            label: <Text>User</Text>,
            icon: <UserOutlined/>,
            key: 'User',
        },
        {
            label: <Text>Subscribe</Text>,
            icon: <CalendarOutlined/>,
            key: 'Subscribe',
        },
    ];

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    return (
        <>
            <Content id={"Dashboard"} className={styles.content}>
                <div style={{
                    minHeight: height,
                    position: "relative",
                    width: "100%",
                    marginTop: 64,
                    paddingTop: 24,
                    paddingBottom: 24
                }}>
                    {
                        session === "active" || session === "success" ?
                            <Layout style={{
                                padding: '24px 0',
                                background: colorBgContainer,
                                width: "100%",
                                height: "80vh"
                            }}>
                                <Sider style={{background: colorBgContainer}} width={200}>
                                    <Menu
                                        mode="inline"
                                        defaultSelectedKeys={[currentPage]}
                                        style={{height: '100%'}}
                                        items={items}
                                        onSelect={(e) => {
                                            setCurrentPage(e.key)
                                        }}
                                        value={[currentPage]}
                                    />
                                </Sider>
                                <Content style={{padding: '0 24px', width: "100%", height: "100%"}}>
                                    {currentPage === "User" && <Profile/>}
                                    {currentPage === "Subscribe" && <Subscribe/>}
                                </Content>
                            </Layout>
                            :
                            <Layout style={{
                                padding: '24px 0',
                                background: colorBgContainer,
                                width: "100%",
                                height: "80vh"
                            }}>
                                <Content style={{padding: '0 24px', width: "100%", height: "100%", alignItems: "center", display: "flex", justifyContent: "center"}}>
                                    <Result
                                        icon={<LoginOutlined style={{color: "red"}}/>}
                                        title={<Title level={2} style={{fontFamily: appFont}}>Please login before getting dashboard!</Title>}
                                        extra={<Button type="primary" onClick={() => {
                                            dispatch(changeLoginModalOpen(true))
                                        }}>Login</Button>}
                                    />
                                </Content>
                            </Layout>
                    }
                </div>
            </Content>
        </>
    )
}

export default Dashboard