// import styles from "./loginMobile.module.css"
import "./loginMobile.css"
import {useState} from "react";
import styles from "../loginComponents/loginform.module.css";
import {Form, Input, Typography} from "antd";
import {loginApi} from "../../../store/reducers/Actions";
import {useDispatch} from "react-redux";

const LoginMobileComponent = () => {

    const [loginActive, setLoginActive] = useState(false);
    const {Title, Text} = Typography
    const dispatch = useDispatch();

    return (
        <div className="form-wrap">
            <div className="tabs">
                <h3 className="signup-tab"><a className={loginActive ? "active" : ""} href="#signup-tab-content" onClick={() => {
                    setLoginActive(!loginActive)
                }}>Sign Up</a></h3>
                <h3 className="login-tab"><a className={loginActive ? "" : "active"} href="#login-tab-content" onClick={() => {
                    setLoginActive(!loginActive)
                }}>Login</a></h3>
            </div>

            <div className={`tabs-content`}>
                <div id="signup-tab-content" className={loginActive ? "active" : ""}>
                    <Title style={{textAlign: "center"}} level={2}>Hello, friend!</Title>
                    <Form className={"login-form"} name="register">
                        <Form.Item
                            style={{width: "100%", marginBottom: 8, marginTop: 8}}
                            name="username"
                            rules={[{required: true, message: 'Please input your username!'}]}
                        >
                            <Input className={"loginInput"} type="text" placeholder="Name"/>
                        </Form.Item>
                        <Form.Item
                            style={{width: "100%", marginBottom: 8, marginTop: 8}}
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                },
                            ]}
                        >
                            <Input className={"loginInput"} type="email" placeholder="Email"/>
                        </Form.Item>
                        <Form.Item
                            style={{width: "100%", marginBottom: 8, marginTop: 8}}
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password className={"loginInput"} type="password" placeholder="Password"/>
                        </Form.Item>
                        <Form.Item style={{width: "100%", marginBottom: 8, marginTop: 8}}>
                            <button className={"buttonLogin"}>Sign Up</button>
                        </Form.Item>
                    </Form>
                    <div className="help-text">
                        <p>By signing up, you agree to our</p>
                        <p><a href="#">Terms of service</a></p>
                    </div>
                </div>

                <div id="login-tab-content" className={loginActive ? "" : "active"}>
                    <Title style={{textAlign: "center"}} level={2}>Welcome back!</Title>
                    <Form className={"login-form"} name="register" onFinish={async (e) => {
                        await dispatch(loginApi({username: e.username, password: e.password}))
                        console.log(e)
                    }}>
                        <Form.Item
                            style={{width: "100%", marginBottom: 8, marginTop: 8}}
                            name="username"
                            rules={[{required: true, message: 'Please input your username!'}]}
                        >
                            <Input className={"loginInput"} type="email" placeholder="Email"/>
                        </Form.Item>
                        <Form.Item
                            style={{width: "100%", marginBottom: 8, marginTop: 8}}
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password className={"loginInput"} type="password" placeholder="Password"/>
                        </Form.Item>
                        <Form.Item>
                            <button className={"buttonLogin"}>
                                Sign In
                            </button>
                        </Form.Item>
                    </Form>
                    <div className="help-text">
                        <p><a href="#">Forget your password?</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginMobileComponent