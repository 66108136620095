import {motion} from "framer-motion";
import {Avatar, Button, Image, theme, Tooltip, Typography} from "antd";
import phone_photo from "../../assets/images/home-app-10.png";
import styles from "./appHomeComponent.module.css"
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Waypoint} from "react-waypoint";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";
import {AntDesignOutlined, DownOutlined, UserOutlined} from "@ant-design/icons";

const AppHomeComponent = (props) => {
    const dispatch = useDispatch();
    const {Title, Text} = Typography
    const {isMobile, darkTheme, width, height, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const changeNav = (args) => {
        dispatch(changeNavigationLink(["Home"]))
        // console.log('Вошли на компонент', args);
    };

    return (
        <div id={"Home"} className={styles.content} style={isMobile ? {height: height * 1.2} : {height: height, marginTop: 64}}>
            <div style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", alignContent: "center", height: "100%"}}>
                <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <Waypoint onEnter={changeNav}
                        // onLeave={handleLeave}
                    >
                        <div style={{textAlign: "center"}}>
                            <Title style={{fontSize: 56, fontFamily: appFont}}>{appTextData.AppHome.title}</Title>
                        </div>
                    </Waypoint>
                    <div className={styles.textInfo}>
                        <Text style={isMobile ? {fontSize: 14, fontFamily: appFont} : {fontSize: 16, fontFamily: appFont}}>{appTextData.AppHome.text}</Text>
                    </div>
                </div>
                <div className={styles.image}>
                    <motion.div
                        initial={{y: 100, opacity: 0}} // начальное состояние компонента (смещение вниз и прозрачность 0)
                        whileInView={{y: 0, opacity: 1}} // конечное состояние компонента (нет смещения и полная прозрачность)
                        transition={{duration: 1}} // длительность анимации
                        viewport={{ once: false }}
                    >
                        <Image preview={false} src={phone_photo}/>
                    </motion.div>
                </div>
                <div style={{display: "flex", flexWrap: "wrap", flexDirection: "column", alignItems: "center"}}>
                    <div>
                        <Button href={"#Download"} size={"large"} shape="round" style={{ marginRight: 10}} type="primary">{appTextData.AppHeader.download}</Button>
                        <Button href={"#Pricing"} size={"large"} shape="round" style={{ marginLeft: 10}}>{appTextData.AppHeader.pricing}</Button>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", marginTop: 30, alignItems: "center", flexWrap: "wrap"}}>
                        <Avatar.Group>
                            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                            <a href="https://ant.design">
                                <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
                            </a>
                            <Tooltip title="Ant User" placement="top">
                                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                            </Tooltip>
                            <Avatar style={{ backgroundColor: '#1677ff' }} icon={<AntDesignOutlined />} />
                        </Avatar.Group>
                        <Text style={{marginLeft: 10}}>{appTextData.AppHome.liked}</Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppHomeComponent