import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Dropdown, Image, Layout, Menu, Space, theme} from 'antd';
import styles from "./main.module.css"
import inline_logo_dark from "../../assets/images/logo_inline_dark.png"
import inline_logo_white from "../../assets/images/logo_inline_white.png"
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeTheme} from "../../store/reducers/deviceInfoSlice";
import phone_photo from "../../assets/images/home-app-10.png"
import { Typography } from 'antd';
import AppHeaderMobile from "../AppHeader/AppHeaderMobile";
import AppHeaderDesktop from "../AppHeader/AppHeaderDesktop";
import AppHomeComponent from "../AppHomeComponent/AppHomeComponent";
import AppFeaturesComponent from "../AppFeaturesComponent/AppFeaturesComponent";
import AppDownloadComponent from "../AppDownloadComponent/AppDownloadComponent";
import AppPricingComponent from "../AppPricingComponent/AppPricingComponent";
import AppClientsComponent from "../AppClientsComponent/AppClientsComponent";
import AppFaqComponent from "../AppFaqComponent/AppFaqComponent";
import AppContactComponent from "../AppContactComponent/AppContactComponent";
import AppFooterDesktop from "../AppFooter/AppFooterDesktop";

const {Header, Content, Footer, Sider} = Layout;
const Home = () => {
    const { Title, Text } = Typography;

    const [collapsed, setCollapsed] = useState(true);
    const dispatch = useDispatch();
    const {isMobile, width} = useSelector(state => state.deviceInfoSlice)

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    return (
        <>
            <Content className={styles.content}>
                <AppHomeComponent />
            </Content>
            <Content className={styles.content}>
                <AppFeaturesComponent />
            </Content>
            <Content className={styles.content}>
                <AppDownloadComponent />
            </Content>
            <Content className={styles.content}>
                <AppPricingComponent />
            </Content>
            <Content className={styles.content}>
                <AppClientsComponent />
            </Content>
            <Content className={styles.content}>
                <AppFaqComponent />
            </Content>
            <Content className={styles.content}>
                <AppContactComponent />
            </Content>
        </>
    );
};
export default Home;