import { createSlice } from '@reduxjs/toolkit';
import {loginApi, tryConnect} from "./Actions";
import { message } from 'antd';

const initialState = {
    username: "",
    subscribe: "",
    autosubscribe: "",
    email: "",
    session_id: "",
    session: "",
    error: false,
    isLoading: false,
    loginModalOpen: false
};

const userSlice = createSlice({
    name: 'userSlice',
    initialState: initialState,
    reducers: {
        logoutAndClear: (state) => {
            state.session = ""
            state.username = ""
            state.email = ""
            state.subscribe = ""
            state.autosubscribe = ""
            state.session_id = ""
            state.error = false
            state.isLoading = false;
        },
        changeLoginModalOpen: (state, action) => {
            state.loginModalOpen = action.payload
        },
        showLogoutSuccessMessage: (state) => {
            // Обработка отображения сообщения об успешном выполнении здесь
            message.success('Вы успешно вышли из системы!');
        },
        showLogoutErrorMessage: (state) => {
            // Обработка отображения сообщения об успешном выполнении здесь
            message.error('Error when logout from account!');
        },
        showLoginSuccessMessage: (state) => {
            // Обработка отображения сообщения об успешном выполнении здесь
            message.success('Вы успешно вошли на аккаунт!');
        },
        showLoginErrorMessage: (state) => {
            // Обработка отображения сообщения об успешном выполнении здесь
            message.error('Ошибка при входе на аккаунт! Проверьте логин и пароль.');
        },
    },
    extraReducers: {
        [tryConnect.pending.type]: (state) => {
            state.isLoading = true;
            state.session = ""
            state.username = ""
            state.email = ""
            state.subscribe = ""
            state.autosubscribe = ""
            state.session_id = ""
        },
        [tryConnect.fulfilled.type]: (state, action) => {
            state.error = false
            state.isLoading = false;
            state.session = action.payload.session
            state.username = action.payload.username
            state.email = action.payload.email
            state.subscribe = action.payload.subscribe
            state.autosubscribe = action.payload.autosubscribe
            state.session_id = action.payload.session_id
        },
        [tryConnect.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.session = ""
            state.username = ""
            state.email = ""
            state.subscribe = ""
            state.autosubscribe = ""
            state.session_id = ""
        },
        [loginApi.pending.type]: (state) => {
            state.isLoading = true;
            state.session = ""
            state.username = ""
            state.email = ""
            state.subscribe = ""
            state.autosubscribe = ""
            state.session_id = ""
        },
        [loginApi.fulfilled.type]: (state, action) => {
            state.error = false
            state.isLoading = false;
            state.session = action.payload.session
            state.username = action.payload.username
            state.email = action.payload.email
            state.subscribe = action.payload.subscribe
            state.autosubscribe = action.payload.autosubscribe
            state.session_id = action.payload.session_id
            if (action.payload.session === "success") {
                state.loginModalOpen = false
                message.success('Вы успешно вошли на аккаунт!');
            } else {
                state.loginModalOpen = true
                message.error('Ошибка при входе на аккаунт! Проверьте логин и пароль.');
            }
        },
        [loginApi.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.session = ""
            state.username = ""
            state.email = ""
            state.subscribe = ""
            state.autosubscribe = ""
            state.session_id = ""
            message.error('Ошибка при входе на аккаунт! Проверьте логин и пароль.');
        }
    }
});

export const { logoutAndClear, changeLoginModalOpen, showLogoutSuccessMessage, showLoginErrorMessage, showLogoutErrorMessage, showLoginSuccessMessage } = userSlice.actions;
export default userSlice.reducer