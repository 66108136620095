import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    navigationLink: ""
};

const navigationSlice = createSlice({
    name: 'navigationSlice',
    initialState: initialState,
    reducers: {
        changeNavigationLink: (state, action) => {
            state.navigationLink = action.payload
        }
    }
});

export const { changeNavigationLink } = navigationSlice.actions;
export default navigationSlice.reducer