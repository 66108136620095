import Icon, {
    CheckCircleOutlined,
    CheckSquareOutlined, CloseCircleOutlined,
    ContainerOutlined, DashboardOutlined,
    EyeOutlined,
    HeartOutlined, LoadingOutlined, LogoutOutlined,
    MenuOutlined,
    UserOutlined
} from "@ant-design/icons";
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Form,
    Image,
    Input,
    Menu,
    message,
    Modal, notification,
    Row,
    Select,
    theme, TimePicker,
    Typography
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import inline_logo_dark from "../../assets/images/logo_inline_dark.png";
import inline_logo_white from "../../assets/images/logo_inline_white.png";
import {useDispatch, useSelector} from "react-redux";
import {changeTheme, setLanguage, setTheme} from "../../store/reducers/deviceInfoSlice";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";

import "./appHeader.css"
import {
    changeCaptchaError,
    changeCaptchaSuccess,
    changeCaptchaVisibility
} from "../../store/reducers/contactPartnerSlice";
import SliderCaptcha from "rc-slider-captcha";
import createPuzzle from "create-puzzle";
import {randomInt} from "ut2";
import city from "../../assets/images/captcha/city.jpg";
import mountain from "../../assets/images/captcha/mountain.jpeg";
import sea from "../../assets/images/captcha/sea.jpg";
import LoginComponent from "./loginComponents/LoginComponent";
import axios from "axios";
import {
    changeLoginModalOpen,
    logoutAndClear,
    reducerCaller, showLogoutErrorMessage,
    showLogoutSuccessMessage
} from "../../store/reducers/userSlice";
import * as emailjs from "emailjs-com";

const AppHeaderDesktop = (props) => {
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const {darkTheme, appLanguage, appFont, appTextData, theme} = useSelector(state => state.deviceInfoSlice)
    const {navigationLink} = useSelector(state => state.navigationSlice)
    const {session, username, loginModalOpen} = useSelector(state => state.userSlice)

    const {Title, Text} = Typography
    const { RangePicker } = TimePicker
    const {Option} = Select;

    const {
        isCaptchaShow,
        isCaptchaError,
        isCaptchaSuccess,
    } = useSelector(state => state.contactPartnerSlice)

    const offsetXRef = useRef(0);
    const captcha_photo = [city, mountain, sea]
    const [form] = Form.useForm();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);

    function scroll(to) {
        const targetElement = document.getElementById(to.substring(1));
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }

    const items = [
        {
            key: 'user',
            type: 'group',
            label: username,
            children: [
                {
                    label: <Link to={"/Dashboard"} onClick={() => {
                        setTimeout(() => {
                            scroll("#Dashboard")
                        }, 500)
                    }}>Dashboard</Link>,
                    key: 'dashboard',
                    icon: <DashboardOutlined/>
                },
                {
                    label: <div onClick={async () => {
                        try {
                            const response =  await axios.get(`https://antinovba.az/nfc/logout_page/`, {
                                withCredentials: true,
                            });
                            console.log(response.data)
                            if (response.data.session === "logout") {
                                dispatch(logoutAndClear())
                                dispatch(showLogoutSuccessMessage())
                                // successLogOut()
                                setIsModalOpen(false)
                            }
                            return response.data
                        } catch (error) {
                            console.warn(`${error.message}`)
                            dispatch(showLogoutErrorMessage())
                            return {session: "false"}
                            // throw new Error(error.message);
                        }
                    }}>Log out</div>,
                    key: "logout",
                    icon: <LogoutOutlined/>
                }
            ],
        }
    ]

    const navItems = [
        {
            label: <Link name="Home" to={"/#Home"} onClick={() => {
                dispatch(changeNavigationLink(["Home"]))
                setTimeout(() => {
                    scroll("#Home")
                }, 500)
            }}>{appTextData.AppHeader.home}</Link>,
            key: 'Home',
        },
        {
            label: <Link name="Features" to={"/#Features"} onClick={() => {
                dispatch(changeNavigationLink(["Features"]))
                setTimeout(() => {
                    scroll("#Features")
                }, 500)
            }}>{appTextData.AppHeader.features}</Link>,
            key: 'Features',
        },
        {
            label: <Link name="Download" to={"/#Download"} onClick={() => {
                dispatch(changeNavigationLink(["Download"]))
                setTimeout(() => {
                    scroll("#Download")
                }, 500)
            }}>{appTextData.AppHeader.download}</Link>,
            key: 'Download',
        }, {
            label: <Link name="Pricing" to={"/#Pricing"} onClick={() => {
                dispatch(changeNavigationLink(["Pricing"]))
                setTimeout(() => {
                    scroll("#Pricing")
                }, 500)
            }}>{appTextData.AppHeader.pricing}</Link>,
            key: 'Pricing',
        },
        {
            label: <Link name="Clients" to={"/#Clients"} onClick={async () => {
                dispatch(changeNavigationLink(["Clients"]))
                setTimeout(() => {
                    scroll("#Clients")
                }, 500)
            }}>{appTextData.AppHeader.clients}</Link>,
            key: 'Clients',
        },
        {
            label: <Link name="FAQ" to={"/#FAQ"} onClick={() => {
                dispatch(changeNavigationLink(["FAQ"]))
                setTimeout(() => {
                    scroll("#FAQ")
                }, 500)
            }}>{appTextData.AppHeader.faq}</Link>,
            key: 'FAQ',
        },
        {
            label: <Link name="Contact" to={"/#Contact"} onClick={() => {
                dispatch(changeNavigationLink(["Contact"]))
                setTimeout(() => {
                    scroll("#Contact")
                }, 500)
            }}>{appTextData.AppHeader.contact}</Link>,
            key: 'Contact',
        },
        {
            label: <button id="btn-gra" className="btn-gra" onClick={() => {
                setIsModalOpen(true)
            }}>{appTextData.AppHeader.partners}</button>,
            key: "Partners"
        }
    ]

    const Moon = () => (
        <svg viewBox="0 0 24 24" height="2em" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M19.9001 2.30719C19.7392 1.8976 19.1616 1.8976 19.0007 2.30719L18.5703 3.40247C18.5212 3.52752 18.4226 3.62651 18.298 3.67583L17.2067 4.1078C16.7986 4.26934 16.7986 4.849 17.2067 5.01054L18.298 5.44252C18.4226 5.49184 18.5212 5.59082 18.5703 5.71587L19.0007 6.81115C19.1616 7.22074 19.7392 7.22074 19.9001 6.81116L20.3305 5.71587C20.3796 5.59082 20.4782 5.49184 20.6028 5.44252L21.6941 5.01054C22.1022 4.849 22.1022 4.26934 21.6941 4.1078L20.6028 3.67583C20.4782 3.62651 20.3796 3.52752 20.3305 3.40247L19.9001 2.30719Z"
                    stroke="#1C274C"></path>
                <path
                    d="M16.0328 8.12967C15.8718 7.72009 15.2943 7.72009 15.1333 8.12967L14.9764 8.52902C14.9273 8.65407 14.8287 8.75305 14.7041 8.80237L14.3062 8.95987C13.8981 9.12141 13.8981 9.70107 14.3062 9.86261L14.7041 10.0201C14.8287 10.0694 14.9273 10.1684 14.9764 10.2935L15.1333 10.6928C15.2943 11.1024 15.8718 11.1024 16.0328 10.6928L16.1897 10.2935C16.2388 10.1684 16.3374 10.0694 16.462 10.0201L16.8599 9.86261C17.268 9.70107 17.268 9.12141 16.8599 8.95987L16.462 8.80237C16.3374 8.75305 16.2388 8.65407 16.1897 8.52902L16.0328 8.12967Z"
                    stroke="#1C274C"></path>
                <path
                    d="M21.0672 11.8568L20.4253 11.469L21.0672 11.8568ZM12.1432 2.93276L11.7553 2.29085V2.29085L12.1432 2.93276ZM7.37554 20.013C7.017 19.8056 6.5582 19.9281 6.3508 20.2866C6.14339 20.6452 6.26591 21.104 6.62446 21.3114L7.37554 20.013ZM2.68862 17.3755C2.89602 17.7341 3.35482 17.8566 3.71337 17.6492C4.07191 17.4418 4.19443 16.983 3.98703 16.6245L2.68862 17.3755ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25V22.75C17.9371 22.75 22.75 17.9371 22.75 12H21.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75V1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75ZM15.5 14.25C12.3244 14.25 9.75 11.6756 9.75 8.5H8.25C8.25 12.5041 11.4959 15.75 15.5 15.75V14.25ZM20.4253 11.469C19.4172 13.1373 17.5882 14.25 15.5 14.25V15.75C18.1349 15.75 20.4407 14.3439 21.7092 12.2447L20.4253 11.469ZM9.75 8.5C9.75 6.41182 10.8627 4.5828 12.531 3.57467L11.7553 2.29085C9.65609 3.5593 8.25 5.86509 8.25 8.5H9.75ZM12 2.75C11.9115 2.75 11.8077 2.71008 11.7324 2.63168C11.6686 2.56527 11.6538 2.50244 11.6503 2.47703C11.6461 2.44587 11.6482 2.35557 11.7553 2.29085L12.531 3.57467C13.0342 3.27065 13.196 2.71398 13.1368 2.27627C13.0754 1.82126 12.7166 1.25 12 1.25V2.75ZM21.7092 12.2447C21.6444 12.3518 21.5541 12.3539 21.523 12.3497C21.4976 12.3462 21.4347 12.3314 21.3683 12.2676C21.2899 12.1923 21.25 12.0885 21.25 12H22.75C22.75 11.2834 22.1787 10.9246 21.7237 10.8632C21.286 10.804 20.7293 10.9658 20.4253 11.469L21.7092 12.2447ZM12 21.25C10.3139 21.25 8.73533 20.7996 7.37554 20.013L6.62446 21.3114C8.2064 22.2265 10.0432 22.75 12 22.75V21.25ZM3.98703 16.6245C3.20043 15.2647 2.75 13.6861 2.75 12H1.25C1.25 13.9568 1.77351 15.7936 2.68862 17.3755L3.98703 16.6245Z"
                    fill="#1C274C"></path>
            </g>
        </svg>
    )

    const Sun = () => (
        <svg viewBox="0 0 24 24" height="2em" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M12 2V3" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M12 21V22" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M22 12L21 12" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M3 12L2 12" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M19.0708 4.92969L18.678 5.32252" stroke="#ffffff" stroke-width="1.5"
                      stroke-linecap="round"></path>
                <path d="M5.32178 18.6777L4.92894 19.0706" stroke="#ffffff" stroke-width="1.5"
                      stroke-linecap="round"></path>
                <path d="M19.0708 19.0703L18.678 18.6775" stroke="#ffffff" stroke-width="1.5"
                      stroke-linecap="round"></path>
                <path d="M5.32178 5.32227L4.92894 4.92943" stroke="#ffffff" stroke-width="1.5"
                      stroke-linecap="round"></path>
                <path
                    d="M6.34141 10C6.12031 10.6256 6 11.2987 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C11.2987 6 10.6256 6.12031 10 6.34141"
                    stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
            </g>
        </svg>
    )

    const onFinish = (values) => {
        sendEmail(values)
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const sendEmail = (values) => {
        const formElement = document.createElement('form');

        // Добавляем скрытое поле для каждого свойства объекта
        Object.entries(values).forEach(([name, value]) => {
            const inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = name;
            inputElement.value = value;
            formElement.appendChild(inputElement);
        });

        const inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "register_type";
        inputElement.value = "partner";
        formElement.appendChild(inputElement);

        // Добавляем форму на страницу, чтобы сработал метод sendForm
        formElement.style.display = 'none';
        document.body.appendChild(formElement);

        emailjs.sendForm('service_m8usqk5', 'template_15b7rln', formElement , '8w2jjjPt6B6-jVFY9')
            .then((result) => {
                setIsEmailSent(true);
                setIsModalOpen(false)
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        // Удаляем временную форму сразу после отправки
        document.body.removeChild(formElement);
    };


    useEffect(() => {
        // Если письмо успешно отправлено (флаг установлен в true), показываем уведомление
        if (isEmailSent) {
            notification.success({
                message: "Success",
                description: "Your form has been sent successfully. We will call you close time!",
            });
            setIsEmailSent(false)
        }
    }, [isEmailSent]);

    useEffect(() => {
        // Если письмо успешно отправлено (флаг установлен в true), показываем уведомление
        if (isEmailError) {
            notification.error({
                message: "Error",
                description: "Error when sending your form",
            });
            setIsEmailError(false)
        }
    }, [isEmailError]);

    return (
        <>
            <Image src={darkTheme ? inline_logo_dark : inline_logo_white} preview={false} width={100}/>
            <Menu
                mode="horizontal"
                style={{background: "transparent", minWidth: "70%", justifyContent: "center"}}
                selectedKeys={navigationLink}
                items={navItems}
            />
            <div style={{width: 100, display: "flex", justifyContent: "center"}}>
                <Button type={"text"} style={{display: "flex", alignItems: "center"}} onClick={() => {
                    if (theme === "dark") {
                        localStorage.setItem('theme', "white");
                        dispatch(setTheme("white"))
                    } else if (theme === "white") {
                        localStorage.setItem('theme', "dark");
                        dispatch(setTheme("dark"))
                    }
                }}>
                    <Icon component={darkTheme ? Sun : Moon}/>
                </Button>
                {
                    session === "success" || session === "active"?
                        <Dropdown menu={{items}}>
                            <Button type={"text"} style={{display: "flex", justifyContent: "center"}}>
                                <UserOutlined style={{fontSize: 20}}/>
                            </Button>
                        </Dropdown>
                        :
                        <Button type={"text"} style={{display: "flex", justifyContent: "center"}} onClick={() => {
                            dispatch(changeLoginModalOpen(true))
                            // setIsLoginModalOpen(true)
                        }}>
                            <UserOutlined style={{fontSize: 20}}/>
                        </Button>
                }
                <Select
                    value={appLanguage}
                    onChange={(e) => {
                        dispatch(setLanguage(e))
                        localStorage.setItem('lang', e);
                    }}
                    options={[
                        {value: 'en', label: 'En'},
                        {value: 'az', label: 'Az'},
                        {value: 'ru', label: 'Ru'},
                    ]}
                />
            </div>
            <Modal title={<div style={{margin: "26px 0px 18px"}}><Text
                style={{fontFamily: appFont, fontSize: 38}}>{appTextData.AppContact.partner}<Text style={{
                fontFamily: appFont,
                fontSize: 38,
                color: "red"
            }}>{appTextData.AppContact.partnerWord}</Text></Text></div>} open={isModalOpen} onOk={() => {
                setIsModalOpen(false)
            }} onCancel={() => {
                setIsModalOpen(false)
            }}>
                <Form
                    form={form}
                    name="contact_form"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 600}}
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label={appTextData.AppContact.name}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={appTextData.AppContact.phone}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                            },
                        ]}
                    >
                        <Input
                            addonBefore={<Select
                                style={{
                                    width: 80,
                                }}
                            >
                                <Option value="...">...</Option>
                                <Option value="+7">+7</Option>
                                <Option value="+380">+380</Option>
                                <Option value="+994">+994</Option>
                            </Select>}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'email'}
                        label="Email"
                        rules={[
                            {
                                type: 'email',
                                required: true,
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="product"
                        label={appTextData.AppContact.product}
                        rules={[
                            {
                                required: true,
                                message: 'Please select product (subscription)!',
                            },
                        ]}
                    >
                        <Select placeholder={appTextData.AppContact.productPlaceHolder}>
                            <Option value="basic">{appTextData.AppPricing.basic}</Option>
                            <Option value="pro">{appTextData.AppPricing.professional}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="time" label={appTextData.AppContact.time}>
                        <RangePicker showTime format="HH:mm" />
                    </Form.Item>
                    <Form.Item
                        name="additional"
                        label={appTextData.AppContact.additionalInfo}
                        tooltip={appTextData.AppContact.additionalText}
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input.TextArea showCount maxLength={100}/>
                    </Form.Item>
                    <Form.Item
                        name="capthca"
                        label="Captcha" extra={appTextData.AppContact.captchaText}
                        rules={[
                            {
                                validator: (_, value) =>
                                    isCaptchaSuccess ? Promise.resolve() : Promise.reject(new Error('Incorrect captcha')),
                            },
                        ]}>
                        <Row gutter={8}>
                            <Col span={12}>
                                <Button onClick={() => {
                                    if (isCaptchaShow === false && isCaptchaSuccess === false) {
                                        dispatch(changeCaptchaVisibility())
                                    }
                                }}>Get captcha</Button>
                            </Col>
                            {isCaptchaSuccess && <CheckCircleOutlined style={{fontSize: 20}}/>}
                            {isCaptchaError && <CloseCircleOutlined style={{fontSize: 20}}/>}
                        </Row>
                    </Form.Item>
                    {isCaptchaShow && !isCaptchaSuccess && <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <SliderCaptcha
                            request={() =>
                                createPuzzle(captcha_photo[Math.floor(Math.random() * captcha_photo.length)], {
                                    x: randomInt(80, 300)
                                }).then((res) => {
                                    offsetXRef.current = res.x;

                                    return {
                                        bgUrl: res.bgUrl,
                                        puzzleUrl: res.puzzleUrl
                                    };
                                })
                            }
                            tipText={{
                                default: 'Drag to the right to complete the puzzle',
                                loading: 'Loading...',
                                moving: 'Drag to the right to the puzzle position'
                            }}
                            loadingBoxProps={{
                                icon: <LoadingOutlined/>,
                                text: "loading"
                            }}
                            onVerify={async (data) => {
                                // console.log(data);
                                if (data.x >= offsetXRef.current - 5 && data.x < offsetXRef.current + 5) {
                                    setTimeout(() => {
                                        dispatch(changeCaptchaSuccess())
                                    }, 1500)
                                    return Promise.resolve();
                                } else {
                                    dispatch(changeCaptchaError())
                                    return Promise.reject();
                                }
                            }}
                            bgSize={{
                                width: 360
                            }}
                            loadingDelay={300}
                        />
                    </div>}
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                            },
                        ]}
                    >
                        <Checkbox>
                            {appTextData.AppContact.agreements}<a href="">{appTextData.AppContact.agreementWord}</a>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            {appTextData.AppContact.submit}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal bodyStyle={{padding: 0}} width={800} footer={<></>} onCancel={() => {dispatch(changeLoginModalOpen(!loginModalOpen))}} open={loginModalOpen}>
                <LoginComponent />
            </Modal>
        </>
    )
}

export default AppHeaderDesktop