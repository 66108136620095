import {Avatar, Calendar, Divider, Form, Input, Typography} from "antd";
import {useSelector} from "react-redux";
import React from "react";
import './subscribe.css'
import {ExclamationCircleFilled} from "@ant-design/icons";


const Subscribe = () => {
    const {session, username, loginModalOpen, subscribe, email, autosubscribe} = useSelector(state => state.userSlice)
    const {Title, Text} = Typography
    const {isMobile, darkTheme, width, height, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)

    const subscribe_array = subscribe.split(" ")[0]
    const data_array = subscribe_array.split("-")

    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    function dateCellRender(date) {
        // console.log(data_array)
        // Здесь вы можете определить, какие даты нужно отметить цветом.
        // В этом примере отметим 8 мая определенным цветом.

        const isSpecialDate = date.year() === parseInt(data_array[0]) && date.month() === (data_array[1] - 1) && date.date() === parseInt(data_array[2]); // Май имеет индекс 4 (январь - 0, февраль - 1, ..., апрель - 3)
        // Проверяем, является ли текущая дата 8 мая.

        return (
            <div className={isSpecialDate ? 'special-date' : ''}>
                {isSpecialDate ? <ExclamationCircleFilled style={{color: "yellow", fontSize: 20}}/> : <span style={{padding: '1px'}}></span>}
            </div>
        );
    }

    return (
        <div>
            <Title level={2}>Subscribe</Title>
            <div style={{display: "flex", marginBottom: 5}}>
                <span style={{width: 20, marginRight: 5, borderRadius: "50%", backgroundColor: "red"}}/><Text> - Current data.</Text>
            </div>
            <div style={{display: "flex"}}>
                <ExclamationCircleFilled style={{color: "yellow", fontSize: 20, paddingRight: 5}}/><Text> - Subscribe end time.</Text>
            </div>
            <Calendar fullscreen={false} dateCellRender={dateCellRender} onPanelChange={onPanelChange} />
        </div>
    )
}

export default Subscribe