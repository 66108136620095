export const appDataText = {
    ru : {
        AppHeader : {
            home: "Главная",
            features: "Особенности",
            download: "Скачать",
            pricing: "Подписки",
            clients: "Клиентам",
            faq: "FAQ",
            contact: "Контакты",
            partners: "Для Партнеров"
        },
        AppHome : {
            title: "Давайте Вас Подключим.",
            text: "С помощью нашей функции мобильной инвентаризации вы можете проводить быструю и точную проверку товаров. Просто сканируя штрих-коды или используя технологию NFC, вы можете легко добавлять или удалять элементы из своей базы данных. Вся информация мгновенно синхронизируется с системой 1С, обновляя данные в режиме реального времени.",
            liked: "Понравилось более 10000 человек, подобных вам."
        },
        AppFeatures : {
            title: "Удобно и минималистично.",
            titleInfo: "С помощью мобильной инвентаризации вы сможете проводить быструю и точную проверку остатков товаров на складе. Простым сканированием штрихкодов или использованием NFC-технологии вы с легкостью добавите или удалите товары в базе данных. Вся информация сразу же синхронизируется с системой 1С, обновляя данные в режиме реального времени.",
            convenienceTitle: "Удобство",
            convenienceText: "Программа предлагает простой и удобный интерфейс, обеспечивая легкость использования и навигации.",
            efficiencyTitle: "Эффективность",
            efficiencyText: "С помощью программы вы сможете значительно повысить эффективность управления базой данных и проведения инвентаризации на предприятии.",
            flexibilityTitle: "Гибкость",
            flexibilityText: "Программа обладает гибкими настройками, позволяя адаптировать ее под уникальные потребности вашего предприятия.",
            realTimeTitle: "Реальное время",
            realTimeText: "Благодаря синхронизации с системой 1С в режиме реального времени, вы всегда будете иметь актуальные данные о вашей базе данных и остатках товаров.",
            mobilityTitle: "Мобильность",
            mobilityText: "Программа позволяет вам управлять базой данных и проводить инвентаризацию прямо с мобильного устройства, обеспечивая гибкость и доступность в любой точке предприятия.",
            reliabilityTitle: "Надежность",
            reliabilityText: "Наша программа обеспечивает высокую степень надежности и безопасности данных, гарантируя сохранность вашей информации и защиту от потери или несанкционированного доступа."
        },
        AppDownload : {
            miniTitle: "ПОДГОТОВКА К НОВОМУ УРОВНЮ",
            title: "Начните сейчас с ",
            text: "ANTINOVBA — ваш надежный партнер в повышении производительности и удобства работы на новом уровне бизнеса.",
            feature1: "Улучшите эффективность своего бизнеса",
            feature2: "Мобильное управление в ваших руках",
            feature3: "Надежность и безопасность",
            download: "Скачать"
        },
        AppPricing : {
            pricing: "Подписки",
            title: "Начните с малого, думайте масштабно",
            titleText: "Познакомьтесь с нашими простыми и элегантными вариантами ценообразования, разработанными для удовлетворения ваших конкретных потребностей. От бюджетных планов для стартапов до комплексных пакетов для крупных предприятий - найдите идеальное решение, соответствующее вашим бизнес-потребностям.",
            priceSecond: "и ₼2/в мес.",
            basic: "Базовый",
            professional: "Проффесиональный",
            basicInfo: "Базовая подписка предоставляет доступ к основным функциям нашей платформы, позволяя вам с легкостью пользоваться основными функциями и контентом.",
            professionalInfo: "Откройте спектр возможностей, которая включает расширенные инструменты и эксклюзивный контент для достижения наивысшего уровня успеха.",
            priceInfo: "После покупки аккаунт остается у вас, однако для работы на каждый месяц требуется подписка 2 ₼/мес.",
            startBasic: "Выбрать Базовый",
            startProfessional: "Выбрать Проффесиональный",
            miniTitle: "Вы получите",
            function1: "Возможность сканировать и регистрировать NFC-метки",
            function2: "Возможность сканировать и регистрировать Штрихкоды и QR-коды",
            function3: "Возможность поиска номенклатуры по наименованию",
            function4: "Возможность управлять инвентарем",
            function5: "Возможность изменять количество товаров по складам",
            function6: "Возможность выбора контрагента",
            function7: "Возможность выбора тип цен"
        },
        AppClients : {
            clients: "КЛИЕНТЫ",
            title1: "Доверьтесь нам и улучшите свой ",
            title2: "бизнесс",
            titleText: "Наши клиенты довольны результатом! Получите уникальные функции, которые помогут вашему бизнесу расти. Присоединяйтесь к нашему успешному сообществу клиентов!",
            installed: "Установлено",
            feedback: "Отзывы",
            installedText: "10+/за 3 дня",
            feedbacks: {
                locitane: {
                    description: "",
                    response: "Не могу не поблагодарить команду за эту программу! Она полностью изменила наше управление складскими запасами. Простая, интуитивно понятная и мощная - это настоящий прорыв!"
                },
                canakkale: {
                    description: "",
                    response: "Абсолютно влюблены в безупречную интеграцию с базой данных 1С. Программа облегчила управление данными, и теперь мы более эффективны в работе."
                },
                bakuDist: {
                    description: "",
                    response: "Функция сканирования штрих-кодов - это находка! Никаких ошибок вручную. Теперь отслеживание инвентаря стало точным и без проблем."
                },
                brgp: {
                    description: "",
                    response: "Впечатлены гибкостью программы. Мы настроили ее под наши конкретные потребности. Отличный выбор для любого бизнеса!"
                },
                euroClima: {
                    description: "",
                    response: "Как владелец малого бизнеса, ценю доступные планы без ущерба функциональности. Это прекрасное вложение в наш рост."
                },
                vienGroup: {
                    description: "",
                    response: "Мобильное управление инвентарем - находка! Доступ к данным в режиме реального времени повысил нашу продуктивность и принятие решений."
                },
                masterService: {
                    description: "",
                    response: "Команда технической поддержки на высшем уровне. Они быстро и помогли решить все вопросы при настройке."
                },
                uniMetal: {
                    description: "",
                    response: "Невероятное соотношение цены и функциональности. Программа стала незаменимым инструментом для нашей компании, оптимизируя процессы."
                }
            }
        },
        AppFaqs: {
            faq: "FAQ",
            title: "Часто задаваемые вопросы",
            text: "«Ответы на наиболее часто задаваемые вопросы помогут вам быстро разобраться в наших услугах».",
            faqs: {
                faq1: {
                    question: "Для чего нужна программа ANTINOVBA, в чем его основной функционал?",
                    answer: "ANTINOVBA - это инновационная программа, разработанная для обеспечения удобства мобильной инвентаризации и удаление очередей засчет встроенной технологии nfc-меток и баркодов."
                },
                faq2: {
                    question: "Чем отличается базовая версия от профессиональной?",
                    answer: "Базовая версия и профессиональная версия ANTINOVBA предлагают разные уровни функциональности и возможностей. В базовой версии вы получаете основные инструменты для обеспечения безопасности данных, в то время как профессиональная версия предоставляет дополнительные расширенные функции и более широкий спектр интеграций, такие как возможность выбор товаров по складам, возможность выбора контрагента и т.д."
                },
                faq3: {
                    question: "Как производится оплата и дальнейшая подписка?",
                    answer: ""
                },
                faq4: {
                    question: "С какими программами можно интегрировать ANTINOVBA?",
                    answer: "ANTINOVBA предоставляет возможность интеграции с различными программами и сервисами, такими как 1C. Мы поддерживаем широкий спектр интеграций, чтобы упростить взаимодействие с вашими существующими инструментами."
                },
                faq5: {
                    question: "Как обеспечивается безопасность данных пользователей в ANTINOVBA?",
                    answer: "ANTINOVBA использует передовые технологии шифрования и защиты, чтобы обеспечить конфиденциальность, целостность и доступность данных. Мы также регулярно аудитируем систему на наличие уязвимостей и обновляем меры безопасности для минимизации рисков."
                },
                faq6: {
                    question: "Как я могу получить техническую поддержку при использовании программы ANTINOVBA?",
                    answer: "Мы предоставляем высококвалифицированную техническую поддержку для всех наших пользователей. Вы можете связаться с нашей командой поддержки по электронной почте support@antinovba.az . Наша команда всегда готова помочь вам с любыми вопросами и проблемами, связанными с использованием нашей программы."
                }
            }
        },
        AppContact: {
            title: "ЗАПОЛНИТЕ ЗАЯВКУ И МЫ СВЯЖЕМСЯ С ВАМИ.",
            text: "Предложение контракта. Мы ценим ваше время и обещаем связаться с вами как можно скорее!",
            name: "Имя",
            phone: "Номер телефона",
            product: "Подписка",
            productPlaceHolder: "выберите желаемый продукт",
            time: "Свободное время",
            additionalInfo: "Доп. инфо",
            additionalText: "Укажите здесь ваши вопросы или предложения, которые вы хотели бы оповестить нам перед обращением",
            captchaText: "Мы должны убедиться, что вы человек.",
            agreements: "Я прочитал ",
            agreementWord: "соглашение",
            submit: "Отправить заявку",
            partner: "Станьте ",
            partnerWord: "партнером"
        },
        AppFooter : {
            rights: "Все права защищены.",
            newsletter: "Подписывайтесь на нашу рассылку"
        },
        ContactForm: {
            successSend: "Ваша форма была успешно доставлена. Мы перезвоним вам в ближайшее время!"
        }
    },
    en : {
        AppHeader : {
            home: "Home",
            features: "Features",
            download: "Download",
            pricing: "Pricing",
            clients: "Clients",
            faq: "FAQs",
            contact: "Contact",
            partners: "For Partners"
        },
        AppHome : {
            title: "Let's Get Connected.",
            text: "With our mobile inventory feature, you can conduct fast and accurate stock checks. By simply scanning barcodes or using NFC technology, you can easily add or remove items from your database. All the information is instantly synchronized with the 1C system, updating the data in real-time.",
            liked: "Loved by 10000+ people like you"
        },
        AppFeatures : {
            title: "Convenient and minimalistic.",
            titleInfo: "Don't waste time on manual data entry and complex inventory processes. Our mobile inventory and database management program for 1C Enterprise streamlines your work and allows you to focus on critical tasks. Take full control of your database right from your mobile device and increase the efficiency of your enterprise today!",
            convenienceTitle: "Convenience",
            convenienceText: "The program offers a simple and user-friendly interface, making it easy to use and navigate.",
            efficiencyTitle: "Efficiency",
            efficiencyText: "With the help of the program, you can significantly increase the efficiency of database management and inventory management in the enterprise.",
            flexibilityTitle: "Flexibility",
            flexibilityText: "The program has flexible settings, allowing you to adapt it to the unique needs of your enterprise.",
            realTimeTitle: "Real time",
            realTimeText: "Thanks to real-time synchronization with the 1C system, you will always have up-to-date data on your database and product balances.",
            mobilityTitle: "Mobility",
            mobilityText: "The software allows you to manage your database and inventory directly from your mobile device, providing flexibility and accessibility anywhere in the enterprise.",
            reliabilityTitle: "Reliability",
            reliabilityText: "Our program provides a high degree of reliability and data security, guaranteeing the safety of your information and protecting it from loss or unauthorized access."
        },
        AppDownload : {
            miniTitle: "PREPARING FOR A NEW LEVEL",
            title: "Start now with ",
            text: "ANTINOVBA is your reliable partner in improving productivity and convenience of work at a new level of business.",
            feature1: "Improve the efficiency of your business",
            feature2: "Mobile management is in your hands",
            feature3: "Reliability and safety",
            download: "Download"
        },
        AppPricing : {
            pricing: "PRICING",
            title: "Start small, think big",
            titleText: "From budget-friendly plans for startups to comprehensive packages for larger enterprises, find the perfect fit for your business needs. Enjoy transparent pricing and exceptional value with ANTINOVBA.",
            priceSecond: "and ₼2/mo.",
            basic: "Basic",
            professional: "Professional",
            basicInfo: "The Basic Subscription provides access to our platform's core features, allowing you to enjoy essential functionalities and content with ease.",
            professionalInfo: "Unlock the full spectrum of possibilities with our Professional Subscription, which includes advanced tools, exclusive content, and a personalized experience for the highest level of success.",
            priceInfo: "After the purchase, the account remains with you, however, a subscription of ₼2/mo is required to work for each month.",
            startBasic: "Start Basic",
            startProfessional: "Start Professional",
            miniTitle: "You will get",
            function1: "Scanning and register nfs tags",
            function2: "Scanning and register barcodes and qr-codes",
            function3: "Search for a nomenclature by its name",
            function4: "Ability to control inventory",
            function5: "Ability to change the quantity of goods in warehouses",
            function6: "Possibility to choose a counterparty",
            function7: "Ability to choose price types."
        },
        AppClients : {
            clients: "CLIENTS",
            title1: "Trust us and improve your ",
            title2: "business",
            titleText: "Our customers are satisfied with the results! Get unique features that will help your business grow. Join our successful customer community!",
            installed: "Installed",
            feedback: "Feedback",
            installedText: "10+/3 days",
            feedbacks: {
                locitane: {
                    description: "",
                    response: "\"Incredible value for the features offered. This program has become an indispensable tool for our organization, streamlining operations like never before.\""
                },
                canakkale: {
                    description: "",
                    response: "\"The customer support team is top-notch. They've been prompt and helpful in addressing any questions or issues we encountered during setup.\""
                },
                bakuDist: {
                    description: "",
                    response: "\"The mobile inventory management is a lifesaver! Being able to access real-time data on-the-go has improved our decision-making and productivity.\""
                },
                brgp: {
                    description: "",
                    response: "\"As a small business owner, I appreciate the budget-friendly pricing without compromising on functionality. It's a fantastic investment for our growth.\""
                },
                euroClima: {
                    description: "",
                    response: "\"Impressed by the versatility of the app. We've been able to customize it to fit our specific needs perfectly. Highly recommended for any business!\""
                },
                vienGroup: {
                    description: "",
                    response: "\"The barcode scanning feature is a time-saver! No more manual input errors. Our inventory tracking has never been this accurate and hassle-free.\""
                },
                masterService: {
                    description: "",
                    response: "\"Absolutely love the seamless integration with 1C database. The program has made data management a breeze, and we're now more efficient than ever.\""
                },
                uniMetal: {
                    description: "",
                    response: "\"I can't thank the team behind this program enough! It has revolutionized the way we manage our inventory. Simple, intuitive, and powerful - it's a game-changer!\""
                }
            }
        },
        AppFaqs: {
            faq: "FAQ",
            title: "Frequently asked questions",
            text: "\"The answers to the most frequently asked questions will help you quickly understand our services.\"\n",
            faqs: {
                faq1: {
                    question: "What is ANTINOVBA and what is its main functionality?",
                    answer: "ANTINOVBA is an innovative program designed to provide convenient mobile inventory management and eliminate queues through the use of built-in NFC tags and barcodes technology."
                },
                faq2: {
                    question: "What are the differences between the Basic and Professional versions of ANTINOVBA?",
                    answer: "The Basic version and Professional version of ANTINOVBA offer different levels of functionality and capabilities. The Basic version provides essential tools for data security, while the Professional version offers additional advanced features and a wider range of integrations, such as the ability to select products by warehouses, choose contractors, and more."
                },
                faq3: {
                    question: "How can I make payments and subscribe to ANTINOVBA?",
                    answer: ""
                },
                faq4: {
                    question: "Which programs can be integrated with ANTINOVBA?",
                    answer: "ANTINOVBA provides the possibility of integration with various programs and services, such as 1C. We support a wide range of integrations to simplify interaction with your existing tools."
                },
                faq5: {
                    question: "How is the security of user data ensured in ANTINOVBA?",
                    answer: "ANTINOVBA uses advanced encryption and protection technologies to ensure the confidentiality, integrity, and availability of data. We also regularly audit the system for vulnerabilities and update security measures to minimize risks."
                },
                faq6: {
                    question: "How can I get technical support when using ANTINOVBA?",
                    answer: "We provide high-quality technical support for all our users. You can contact our support team via email support@antinovba.az. Our team is always ready to assist you with any questions and issues related to using our program."
                }
            }
        },
        AppContact: {
            title: "FILL THE FORM AND WE WILL CONTACT YOU.",
            text: "Contract offer. We appreciate your time and promise to contact you as soon as possible!",
            name: "Name",
            phone: "Phone Number",
            email: "Email",
            product: "Product",
            productPlaceHolder: "select you product",
            time: "Free time",
            additionalInfo: "Additional info",
            additionalText: "Specify here your questions or suggestions that you want to convey to us when contacting",
            captchaText: "We must make sure that your are a human.",
            agreements: "I have read the ",
            agreementWord: "agreement",
            submit: "Submit your form",
            partner: "Become a ",
            partnerWord: "partner"
        },
        AppFooter : {
            rights: "All rights reserved",
            newsletter: "Subscribe to our newsletter"
        },
        ContactForm: {

        }
    },
    az : {
        AppHeader : {
            home: "Əsas səhifə",
            features: "Xüsusiyyətlər",
            download: "Yükləmə",
            pricing: "Giymət",
            clients: "Müştərilər",
            faq: "Sual-Cavab",
            contact: "Əlaqə",
            partners: "Tərəfdaş ol"
        },
        AppHome : {
            title: "Siz də qoşulun",
            text: "Bizim mobil proqram vasitəsi ilə siz anbarda mallarınızı, əsas vəsaitlərinizi və digər aktivlərinizi tez və dəqiq yoxlaya bilərsiniz. Sadəcə ştrix-kodları skan etməklə və ya NFC stiker istifadə etməklə siz asanlıqla böyük məlumatı tez bir zaman qeydiyyata sala bilərsiniz. Bütün məlumatlar dərhal 1C-də və ya digər proqram təminatında əks olunur. Mübadilə yoxdur, bir başa smartfondan iş bazaya.",
            liked: "Bizim işimiz müxtəlif biznes sahələrdə tətbiq olunur."
        },
        AppFeatures : {
            title: "Rahat və sadə",
            titleInfo: "Satış, inventarizasiya, və digər sənədlər bir proqramda, cədvəli doldurun və istədiyiniz sənədə avtomatik ötürün. Barkod və ya NFC stiker sadəcə oxumaq lazımdır.",
            convenienceTitle: "Rahatlıq",
            convenienceText: "Sadə interfeys, proqramın istifadəsi sizin üçün iş prosesdən həzz almaqa kömək edir",
            efficiencyTitle: "Effektivlik",
            efficiencyText: "ANTİNOVBA vasitəsi ilə siz müəssisənin idarəçiliyi asanlaşdırırsız, və tez bir zaman nəticələri əldə edə bilirsiz",
            flexibilityTitle: "Çevik",
            flexibilityText: "Proqramda onu müəssisənizin unikal ehtiyaclarına uyğunlaşdırmağa imkan verən çevik parametrlər var",
            realTimeTitle: "Real Time",
            realTimeText: "1C və ya digər uçot sistemi ilə real vaxt sinxronizasiyası sayəsində siz həmişə verilənlər bazanız və məhsul balansınız haqqında ən son məlumatlara sahib olacaqsınız.",
            mobilityTitle: "Mobil",
            mobilityText: "Proqram resursları idarə etməyə imkan verir: müəssisənin istənilən yerində rahatlıq və əlçatanlığı təmin edərək birbaşa mobil cihazınızdan satmaq, yenidən hesablamaq və digər əməliyyatları həyata keçirmək.",
            reliabilityTitle: "Etibarlıq",
            reliabilityText: "Bizim proqramımız məlumatınızın təhlükəsiz olmasını və itkidən və ya icazəsiz girişdən qorunmasını təmin edərək yüksək dərəcədə məlumatların etibarlılığını və təhlükəsizliyini təmin edir."
        },
        AppDownload : {
            miniTitle: "Növbəti səviyyəyə hazır ol!",
            title: "İndi başla: ",
            text: "ANTINOVBA — Yeni səviyyədə və Yüksək göstəricilər və rahatlıq ilə sizin etibarlı tərəfdaşınızdır.",
            feature1: "Biznesinizin effektliyini artırın",
            feature2: "Mobil prosseslər sizin əlinizdə",
            feature3: "Etibarlıq və təhlükəsizlik",
            download: "Endir"
        },
        AppPricing : {
            pricing: "Qiymət",
            title: "Kiçikdən başlayaq və böyük düşünək.",
            titleText: "Познакомьтесь с нашими простыми и элегантными вариантами ценообразования, разработанными для удовлетворения ваших конкретных потребностей. От бюджетных планов для стартапов до комплексных пакетов для крупных предприятий - найдите идеальное решение, соответствующее вашим бизнес-потребностям.",
            priceSecond: "və ₼2/hər ayda",
            basic: "Start",
            professional: "Proffesional",
            basicInfo: "\"Start\" versiyası ilə bağlı mövcud olan funksiyonallıqlar və xüsusiyyətlər, yalnız əsas tələbatı olan və müştərilərinizə sadə və başlanğıc səviyyədə funksiyonallıq təmin etmək üçün istifadə oluna bilər. Bu versiyada mövcud olan bir neçə özəllik və funksiyonları təqdim edin və müştərilərin bu versiya ilə nəyə nail olacağını aydın şəkildə göstərin.",
            professionalInfo: "\"Prof\" versiyası isə müştərilərin daha çox tələbatlarını qarşılamak üçün daha müstəqil və müasir funksiyonallıq təklif edirik. Daha yüksək səviyyədə funksiyonallıq, əlavə xüsusiyyətlər və ya əlavə dəstək və servis təklif əldə edə bilərsiniz. Bu versiyada daha geniş və təkmil funksiyonlarla müştərilərinizi maraqlandırmağa çalışın.",
            priceInfo: "После покупки аккаунт остается у вас, однако для работы на каждый месяц требуется подписка 2 ₼/мес.",
            startBasic: "\"Start\" seçmək",
            startProfessional: "\"Prof.\" seçmək",
            miniTitle: "Əldə edəcəksiniz:",
            function1: "NFC etiketlərini skan etmək və qeydiyyatdan keçirmək imkanı",
            function2: "Barkodları və QR kodları skan etmək və qeydiyyatdan keçirmək imkanı",
            function3: "Məhsul adına görə nomenklaturanın axtarış imkanı",
            function4: "Inventar idarə etmə imkanı",
            function5: "Anbarlara görə mal sayını dəyişmə imkanı",
            function6: "Kontragent seçimi imkanı",
            function7: "Qiymət tipi seçimi imkanı"
        },
        AppClients : {
            clients: "Müştərilər",
            title1: "Bizə etibar edin və işinizi ",
            title2: "yaxşılaşdırın",
            titleText: "Hər bir müştəri bizim üçün unikal və qiymətlidir və onların tələbatlarına cavab vermək üçün mobil proqramlarımızı daim təkmilləşdiririk. Müştərilərimizin feedbackləri və istəkləri bizim üçün vacib olduğundan, müştəri məmnuniyyətini həmişə öncəyə tuturuq.",
            installed: "Quraşdırıldı",
            feedback: "Rəylər",
            installedText: "10+/3 günə",
            feedbacks: {
                locitane: {
                    description: "",
                    response: "Mobil proqramlarınız işimizi dəyişdi! İstifadəsi çox sadə, əlaqə qurduğumuz digər proqramlarla inteqrasiyası asandır. Təşəkkürlər!"
                },
                canakkale: {
                    description: "",
                    response: "Qiymətlərimizə nisbətən ucuz bir qiymətə çox funksiyonallı bir mobil proqram almağımızı bəyəndik. Çox məmnunuz!"
                },
                bakuDist: {
                    description: "",
                    response: "Proqramınızı təsdiq edirik! Çox təhlilçi və ətraflı funksiyonlar təklif edir və işimizi asanlaşdırır."
                },
                brgp: {
                    description: "",
                    response: "Müştəri dəstəyiniz çox yüksək səviyyədədir! Hər bir sualımı və problemimi sürətlə həll edirsiniz. Təşəkkürlər!"
                },
                euroClima: {
                    description: "",
                    response: "Start versiyası bizim üçün kifayət qədər sadə və funksionaldır. Maliyyə tənzimləməmizi dəqiq həyata keçirmək üçün mükəmməl bir vasitədir."
                },
                vienGroup: {
                    description: "",
                    response: "Prof versiyasında olan satış analitikası və təqdim etdiyiniz dəqiq məlumatlar bizi həmişə öndə aparmağa kömək edir."
                },
                masterService: {
                    description: "",
                    response: "Mobil proqramınız sayəsində əməkdaşlarımız daha təmkinli və səhvə düşməyən bir tərifdə işləyir. Sizi təbrik edirik!"
                },
                uniMetal: {
                    description: "",
                    response: "Müştəri referanslarınızı ehtiramla qəbul edirik. Proqramınızın gücünü başqalarına da təklif edəcəyik!"
                }
            }
        },
        AppFaqs: {
            faq: "Sual-cavab",
            title: "Tez-Tez verilən suallar",
            text: "«Əsas olaraq mobil proqramınızla bağlı tez-tez verilən suallar və onlara uyğun cavablar».",
            faqs: {
                faq1: {
                    question: "Proqramınızda necə məhsul əlavə etmək olar?",
                    answer: "Məhsul əlavə etmək üçün, proqramın administrativ panelindən sadəcə \"Məhsullar\" bölməsinə daxil olun və \"Əlavə et\" düyməsini seçin. Məhsulun adını, kodunu, qiymətini və digər ətraflı məlumatları daxil edə bilərsiniz."
                },
                faq2: {
                    question: "NFC stikerləri ilə necə mal saymaq olar?",
                    answer: "NFC stikerləri ilə mal saymaq üçün, proqramın \"NFC icon\" funksiyasını seçin və növbəti stikeri cihazınızın arxasına yaxınlaşdırın. Malın məlumatları avtomatik olaraq oxunacaq və sayılara daxil ediləcək."
                },
                faq3: {
                    question: "\"Start\" və \"Prof\" versiyaları arasındakı fərqlər nədir?",
                    answer: "\"Start\" versiyası daha sadə və əsas funksiyonları təklif edir, \"Prof\" versiyası isə daha təkmil və əlavə funksiyonlar ilə zənginləşdirilmişdir. \"Prof\" versiyasında daha geniş satış analitikası və təqdim etdiyimiz əlavə xüsusiyyətlər mövcuddur."
                },
                faq4: {
                    question: "Mobil proqramınız digər uçot proqramları ilə inteqrasiya edilir?",
                    answer: "Bəli, mobil proqramımız 1C Müəssisə və digər uçot proqramları ilə rahat və sürətli inteqrasiya etmək üçün API-lər təklif edir."
                },
                faq5: {
                    question: "Proqramımızın dəstəkləməsi üçün əlaqə saxlamaq üçün necə edim?",
                    answer: "Bizimlə əlaqə qurmak üçün \"Əlaqə\" bölməsindən bizə e-mail göndərə və ya telefonla əlaqə saxlaya bilərsiniz. Dəstək komandası sürətlə cavab verəcək və bütün suallarınıza kömək edəcəkdir."
                },
                faq6: {
                    question: "Mobil proqramı təlimatlarını nəzərdən keçirmək üçün nə etməliyəm?",
                    answer: "Mobil proqramın təlimatları \"Təlimatlar\" bölməsində mövcuddur. Orada məhsul əlavə etməkdən, satış və yerdəyişmə proseslərini təyin etməyə qədər müxtəlif təlimatlar tapa bilərsiniz. Əgər ətraflı təlimatlar vəya videolar ilə kömək lazımdırsa, dəstək komandasına yazın və onlar sizə yardım edəcəklər."
                }
            }
        },
        AppContact: {
            title: "QEYDIYYAT FORMASINI DOLDURUN VƏ SIZINLƏ ƏLAQƏ SAXLAYACAĞIQ.",
            text: "Müqavilə təklifi. Sizin vaxtınızı qiymətləndiririk və mümkün qədər tez əlaqə saxlayacağımızı söyləyirik!",
            name: "Ad",
            phone: "Telefon nömrəsi",
            product: "Abunə",
            productPlaceHolder: "İstədiyiniz məhsulu seçin",
            time: "Boş vaxt",
            additionalInfo: "Əlavə məlumat",
            additionalText: "Əvvəlcədən bilmək istədiyiniz suallarınızı və ya bizə bildirmək istədiyiniz təklifləri bu bölmədə göstərin.",
            captchaText: "Siz insan olduğunuzu təsdiq etməliyik.",
            agreements: "",
            agreementWord: "Mən razılaşmanı oxudum.",
            submit: "Zəruri formanı göndər",
            partner: "Tərəfdaş  ",
            partnerWord: "olun"
        },
        AppFooter : {
            rights: "Bütün hüquqlar qorunur.",
            newsletter: "Bizim bülletenimizə abunə olun"
        },
        ContactForm: {
            successSend: "Formanız uğurla çatdırıldı. Ən yaxın zamanda sizə geri zəng edəcəyik!"
        }
    },
}