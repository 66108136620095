import {Avatar, Divider, Form, Input, Typography} from "antd";
import {useSelector} from "react-redux";
import React from "react";


const Profile = () => {
    const {session, username, loginModalOpen, subscribe, email, autosubscribe} = useSelector(state => state.userSlice)
    const {Title, Text} = Typography
    const {isMobile, darkTheme, width, height, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)

    return (
        <div>
            <Title level={2}>Profile details</Title>
            <div style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                <Avatar size={64} style={{ backgroundColor: 'rgba(255,0,0,0.7)'}}>{username.substring(0, 1).toUpperCase()}</Avatar>
                <Text style={{fontFamily: appFont, fontSize: 18, paddingLeft: 10}}>{username} ({subscribe})</Text>
            </div>
            <Divider />
            <Form>
                <Form.Item
                    label={<Text style={{fontWeight: "bold"}}>Username</Text>}
                    name="username"
                >
                    <Text>{username}</Text>
                </Form.Item>
                <Form.Item
                    label={<Text style={{fontWeight: "bold"}}>Email</Text>}
                    name="email"
                >
                    <Text>{email}</Text>
                </Form.Item>
                <Form.Item
                    label={<Text style={{fontWeight: "bold"}}>Subscribe</Text>}
                    name="subscribe"
                >
                    <Text>{subscribe}</Text>
                </Form.Item>
                <Form.Item
                    label={<Text style={{fontWeight: "bold"}}>Auto subscribe</Text>}
                    name="autoSubscribe"
                >
                    <Text>{autosubscribe === "False" ? "-" : "+"}</Text>
                </Form.Item>
                {/*<Text>Username: {username}</Text>*/}
                {/*<Text>Email: {email}</Text>*/}
            </Form>
        </div>
    )
}

export default Profile