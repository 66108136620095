/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 .\src\assets\glb\pixel.glb 
*/

import React, {useEffect, useRef, useState} from 'react'
import glb from "../assets/glb/pixel.glb"
import {useGLTF} from '@react-three/drei'
import {useFrame, useThree} from "@react-three/fiber";
import {useSelector} from "react-redux";

export function ModelPixel(props) {
    const {nodes, materials} = useGLTF(glb)
    const {camera} = useThree(); // Получаем ссылку на камеру

    const myMesh = React.useRef();
    const [isRotating, setIsRotating] = useState(true);
    const rotationSpeed = 0.01

    const {isMobile} = useSelector(state => state.deviceInfoSlice)

    useFrame(({clock}) => {
        if (isRotating && !isMobile) {
            myMesh.current.rotation.y += rotationSpeed;
        }
    });

    const stopRotationForTime = () => {
        setIsRotating(false);
    };

    const resumeRotation = () => {
        setTimeout(() => setIsRotating(true), 2000); // Время в миллисекундах (2 секунды в этом примере)
    };

    useEffect(() => {
        // Задаем стандартное значение позиции камеры
        camera.position.set(-172, 0, -280); // Пример: ставим камеру на расстояние 5 единиц от начала координат
        // myMesh.current.rotation.set([1, 0, 0]);
    }, [])

    return (
        <group {...props} dispose={null}>
            <group position={[0, 20, 0]} draggable={false}>
                <group scale={18} ref={myMesh} onPointerUp={resumeRotation} onPointerDown={stopRotationForTime}>
                    <mesh geometry={nodes.Plane.geometry} material={materials.Frame}/>
                    <mesh geometry={nodes.Plane_1.geometry} material={materials.CamModule}/>
                    <mesh geometry={nodes.Plane_2.geometry} material={materials.CamModuleBlack}/>
                    <mesh geometry={nodes.Plane_3.geometry} material={materials.CamGlass}/>
                    <mesh geometry={nodes.Plane_4.geometry} material={materials.Mic}/>
                    <mesh geometry={nodes.Plane_5.geometry} material={materials.Antennas}/>
                    <mesh geometry={nodes.BackBody001.geometry} material={materials.Logo}/>
                    <mesh geometry={nodes.Buttons.geometry} material={materials.Frame}/>
                    <mesh geometry={nodes.CircleCam.geometry} material={materials.CamGray}/>
                    <mesh geometry={nodes.CircleCam2.geometry} material={materials.CamGray}/>
                    <mesh geometry={nodes.Flash.geometry} material={materials.Flash}/>
                    <mesh geometry={nodes.Lens.geometry} material={materials.Lens}/>
                    <mesh geometry={nodes.Lens1.geometry} material={materials.Lens}/>
                    <mesh geometry={nodes.Lens2.geometry} material={materials.Lens}/>
                    <mesh geometry={nodes.Lens3.geometry} material={materials.Lens}/>
                    <mesh geometry={nodes.Screen.geometry} material={materials.Материал}/>
                    <mesh geometry={nodes.Sensor.geometry} material={materials['Sensor.001']}/>
                    <mesh geometry={nodes.Sensor2.geometry} material={materials.Sensor}/>
                    <mesh geometry={nodes.Plane004.geometry} material={materials['Back(top)']}/>
                    <mesh geometry={nodes.Plane004_1.geometry} material={materials['Back(Bottom']}/>
                    <mesh geometry={nodes.Plane001.geometry} material={materials.Mic}/>
                    <mesh geometry={nodes.Plane001_1.geometry} material={materials.Bezel}/>
                    <mesh geometry={nodes.Circle001.geometry} material={materials.CamGlass}/>
                    <mesh geometry={nodes.Circle001_1.geometry} material={materials.CamGray}/>
                    <mesh geometry={nodes.Circle006.geometry} material={materials.Mic}/>
                    <mesh geometry={nodes.Circle006_1.geometry} material={materials.Mic2}/>
                    <mesh geometry={nodes.Plane007.geometry} material={materials.CamGlass}/>
                    <mesh geometry={nodes.Plane007_1.geometry} material={materials.CamGray}/>
                </group>
            </group>
        </group>
    )
}

useGLTF.preload(glb)
