import GLBViewer from "../GLB/GLBViewer";
import {useDispatch, useSelector} from "react-redux";
import {motion} from "framer-motion";
import React from "react";
import {Button, Typography} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";
import styles from "./appDownloadComponent.module.css"
import {Waypoint} from "react-waypoint";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";
import app from "../../App";

const AppDownloadComponent = () => {
    const dispatch = useDispatch();
    const {isMobile, darkTheme, width, height, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)
    const {Title, Paragraph, Text, Link} = Typography;

    const changeNav = (args) => {
        dispatch(changeNavigationLink(["Download"]))
        // console.log('Вошли на компонент', args);
    };

    return (
        <div id={"Download"} style={isMobile ? {
            minHeight: height + 64,
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center"
        } : {
            height: height + 64,
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center"
        }}>
            <Waypoint onEnter={changeNav}
                // onLeave={handleLeave}
            >
                <div className={styles.textInfoBox}>
                    <div>
                        <Text className={styles.helpText}>{appTextData.AppDownload.miniTitle}</Text>
                    </div>
                    <div>
                        <Text style={{fontSize: 38, fontWeight: 600, fontFamily: appFont}}>{appTextData.AppDownload.title}<Text
                            style={{fontSize: 38, fontWeight: 600, color: "#ff0000", fontFamily: appFont}}>ANTI</Text>NOVBA</Text>
                    </div>
                    <div style={{marginTop: 10, marginBottom: 10}}>
                        <Text style={{fontFamily: appFont}}>{appTextData.AppDownload.text}</Text>
                    </div>
                    <div style={{marginTop: 10, marginBottom: 20}}>
                        <div>
                            <CheckCircleOutlined style={{color: "#ff0000", marginRight: 10}}/>
                            <Text style={{fontFamily: appFont}}>{appTextData.AppDownload.feature1}</Text>
                        </div>
                        <div>
                            <CheckCircleOutlined style={{color: "#ff0000", marginRight: 10}}/>
                            <Text style={{fontFamily: appFont}}>{appTextData.AppDownload.feature2}</Text>
                        </div>
                        <div>
                            <CheckCircleOutlined style={{color: "#ff0000", marginRight: 10}}/>
                            <Text style={{fontFamily: appFont}}>{appTextData.AppDownload.feature3}</Text>
                        </div>
                    </div>
                    <Button size={"large"} shape="round" style={{marginRight: 10, width: 150}} type="primary"><Text
                        style={{fontFamily: appFont}}>{appTextData.AppHeader.download}</Text></Button>
                </div>
            </Waypoint>
            <motion.div
                className={styles.object}
                style={isMobile ? {height: height*0.5, marginTop: 50} : {}}
                initial={{y: 100, opacity: 0}} // начальное состояние компонента (смещение вниз и прозрачность 0)
                whileInView={{
                    y: 0,
                    opacity: 1
                }} // конечное состояние компонента (нет смещения и полная прозрачность)
                transition={{duration: 1}} // длительность анимации
                viewport={{once: false}}
            >
                <GLBViewer/>
            </motion.div>
        </div>
    )
}

export default AppDownloadComponent