import React from 'react';
import {Route, Routes} from "react-router-dom";
import {routes} from "../../urls/urls";
import Main from "../blocks/Main";
import Home from "../blocks/Home";
import Dashboard from "../Dashboard/Dashboard";

const RoutesComp = () => {
    return (
        <Routes>
            <Route path={routes.home} element={<Main/>}>
                <Route path={routes.home} element={<Home />} />
                <Route path={routes.dashboard} element={<Dashboard />} />
            </Route>


            {/*<Route path={routes.home} element={<PrivateRoute />}>*/}
            {/*<Route path={routes.home} element={<Home />} />*/}
            {/*<Route path={routes.group} element={<Group />} />*/}
            {/*</Route>*/}


            {/*<Route path="*" element={<NotFound />} />*/}
        </Routes>
    );
};

export default RoutesComp;