import GLBViewer from "../GLB/GLBViewer";
import {useDispatch, useSelector} from "react-redux";
import {motion} from "framer-motion";
import React from "react";
import {Button, Collapse, Tooltip, Typography} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";
import styles from "./appFaqComponent.css"
import {Waypoint} from "react-waypoint";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";

const AppFaqComponent = () => {
    const dispatch = useDispatch();
    const {isMobile, darkTheme, width, height, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)
    const {Title, Paragraph, Text, Link} = Typography;

    const changeNav = (args) => {
        dispatch(changeNavigationLink(["FAQ"]))
        // console.log('Вошли на компонент', args);
    };

    const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
    const items = [
        {
            key: '1',
            label: <Text style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq1.question}</Text>,
            children: <p style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq1.answer}</p>,
        },
        {
            key: '2',
            label: <Text style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq2.question}</Text>,
            children: <p style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq2.answer}</p>,
        },
        {
            key: '3',
            label: <Text style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq3.question}</Text>,
            children: <p style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq3.answer}</p>,
        },
        {
            key: '4',
            label: <Text style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq4.question}</Text>,
            children: <p style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq4.answer}</p>,
        },
        {
            key: '5',
            label: <Text style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq5.question}</Text>,
            children: <p style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq5.answer}</p>,
        },
        {
            key: '6',
            label: <Text style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq6.question}</Text>,
            children: <p style={{fontFamily: appFont}}>{appTextData.AppFaqs.faqs.faq6.answer}</p>,
        },

    ];

    return (
        <div id={"FAQ"} style={{display: "flex", minHeight: height, width: "100%", justifyContent: "center", flexDirection: "column"}}>
            <div style={{display: "flex", width: "100%", minHeight: "10%", textAlign: "center", alignItems: "center", justifyContent: "center", flexDirection: "column", paddingBottom: 50}}>
                <Waypoint onEnter={changeNav}
                    // onLeave={handleLeave}
                >
                    <Text style={{fontFamily: "Poppins", fontWeight: "500", color: "red", fontSize: 18}}>FAQ</Text>
                </Waypoint>
                <Text style={{fontFamily: appFont, fontWeight: "600", fontSize: 36}}>{appTextData.AppFaqs.title}</Text>
                <div style={isMobile? {maxWidth: "90%"} : {maxWidth: "60%"}}>
                    <Text style={{fontFamily: appFont}}>{appTextData.AppFaqs.text}</Text>
                </div>
            </div>
            <div>
                <Collapse bordered={false} items={items} onChange={() => {}} />
            </div>
        </div>
    )
}

export default AppFaqComponent