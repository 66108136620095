import {configureStore} from "@reduxjs/toolkit";
import deviceInfoSlice from "./reducers/deviceInfoSlice";
import navigationSlice from "./reducers/navigationSlice";
import contactSlice from "./reducers/contactSlice";
import contactPartnerSlice from "./reducers/contactPartnerSlice";
import userSlice from "./reducers/userSlice";

const store = configureStore({
    reducer: {
        deviceInfoSlice: deviceInfoSlice,
        navigationSlice: navigationSlice,
        contactSlice: contactSlice,
        contactPartnerSlice: contactPartnerSlice,
        userSlice: userSlice

    },
});

export default store