import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Dropdown, Image, Layout, Menu, Space, theme} from 'antd';
import styles from "./main.module.css"
import inline_logo_dark from "../../assets/images/logo_inline_dark.png"
import inline_logo_white from "../../assets/images/logo_inline_white.png"
import {NavLink, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeTheme} from "../../store/reducers/deviceInfoSlice";
import phone_photo from "../../assets/images/home-app-10.png"
import { Typography } from 'antd';
import AppHeaderMobile from "../AppHeader/AppHeaderMobile";
import AppHeaderDesktop from "../AppHeader/AppHeaderDesktop";
import AppHomeComponent from "../AppHomeComponent/AppHomeComponent";
import AppFeaturesComponent from "../AppFeaturesComponent/AppFeaturesComponent";
import AppDownloadComponent from "../AppDownloadComponent/AppDownloadComponent";
import AppPricingComponent from "../AppPricingComponent/AppPricingComponent";
import AppClientsComponent from "../AppClientsComponent/AppClientsComponent";
import AppFaqComponent from "../AppFaqComponent/AppFaqComponent";
import AppContactComponent from "../AppContactComponent/AppContactComponent";
import AppFooterDesktop from "../AppFooter/AppFooterDesktop";

const {Header, Content, Footer, Sider} = Layout;
const Main = () => {
    const { Title, Text } = Typography;

    const [collapsed, setCollapsed] = useState(true);
    const dispatch = useDispatch();
    const {isMobile, width} = useSelector(state => state.deviceInfoSlice)

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    return (
        <Layout className={styles.layout}>
            <Header
                className={styles.header}
                style={{maxWidth: width}}
            >
                {isMobile ? <AppHeaderMobile /> : <AppHeaderDesktop />}
            </Header>
            <Outlet />
            <Footer>
                <Content className={styles.content}>
                    <AppFooterDesktop />
                </Content>
            </Footer>
        </Layout>
    );
};
export default Main;