import {Typography} from "antd";
import {FacebookOutlined, InstagramOutlined, LinkedinOutlined} from "@ant-design/icons";
import {Button, Input, Space} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";

const AppFooterDesktop = () => {
    const {Title, Text} = Typography
    const dispatch = useDispatch();
    const {darkTheme, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)

    return (
        <div style={{display: "flex", justifyContent: "space-between", width: "100%", flexWrap: "wrap"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{margin: "18px 0px 12px"}}>
                    <Text style={{fontSize: 30, fontWeight: "600", color: "red", fontFamily: appFont}}>ANTI<Text
                        style={{fontSize: 30, fontWeight: "600", fontFamily: appFont}}>NOVBA</Text></Text>
                </div>
                <Text>©2022 {appTextData.AppFooter.rights}</Text>
                <div>
                    <LinkedinOutlined style={{fontSize: 24, paddingTop: 10, color: "red", paddingRight: 10}}/>
                    <FacebookOutlined style={{fontSize: 24, paddingTop: 10, color: "red", paddingRight: 10}}/>
                    <InstagramOutlined style={{fontSize: 24, paddingTop: 10, color: "red", paddingRight: 10}}/>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
                <Title style={{fontFamily: appFont}} level={3}>Company</Title>
                <a style={darkTheme ? {paddingTop: 2, paddingBottom: 2, color: "white"} : {paddingTop: 2, paddingBottom: 2, color: "black"}} name="Home" href={"#Home"} onClick={() => {dispatch(changeNavigationLink(["Home"]))}}>Home</a>
                <a style={darkTheme ? {paddingTop: 2, paddingBottom: 2, color: "white"} : {paddingTop: 2, paddingBottom: 2, color: "black"}} name="Features" href={"#Features"} onClick={() => {dispatch(changeNavigationLink(["Features"]))}}>Features</a>
                <a style={darkTheme ? {paddingTop: 2, paddingBottom: 2, color: "white"} : {paddingTop: 2, paddingBottom: 2, color: "black"}} name="Download" href={"#Download"} onClick={() => {dispatch(changeNavigationLink(["Download"]))}}>Download</a>
                <a style={darkTheme ? {paddingTop: 2, paddingBottom: 2, color: "white"} : {paddingTop: 2, paddingBottom: 2, color: "black"}} name="Pricing" href={"#Pricing"} onClick={() => {dispatch(changeNavigationLink(["Pricing"]))}}>Pricing</a>
                <a style={darkTheme ? {paddingTop: 2, paddingBottom: 2, color: "white"} : {paddingTop: 2, paddingBottom: 2, color: "black"}} name="Clients" href={"#Clients"} onClick={() => {dispatch(changeNavigationLink(["Clients"]))}}>Clients</a>
                <a style={darkTheme ? {paddingTop: 2, paddingBottom: 2, color: "white"} : {paddingTop: 2, paddingBottom: 2, color: "black"}} name="FAQ" href={"#FAQ"} onClick={() => {dispatch(changeNavigationLink(["FAQ"]))}}>FAQs</a>
            </div>
            <div>
                <Title style={{fontFamily: appFont}} level={3}>{appTextData.AppFooter.newsletter}</Title>
                <Space.Compact style={{width: '100%'}}>
                    <Input placeholder="Enter your mail"/>
                    <Button type="primary">Subscribe now</Button>
                </Space.Compact>
            </div>
        </div>
    )
}

export default AppFooterDesktop