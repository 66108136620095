import { createSlice } from '@reduxjs/toolkit';
import {appDataText} from "../../assets/textData/AppDataText";

const initialState = {
    isMobile: false,
    height: window.innerHeight,
    width: window.innerWidth,
    darkTheme: "true",
    appLanguage: "en",
    appTextData: appDataText.en,
    appFont: "Poppins",
    theme: "dark"
};

const deviceInfoSlice = createSlice({
    name: 'deviceInfoSlice',
    initialState: initialState,
    reducers: {
        changeScreenSize: (state, action) => {
            state.isMobile = action.payload
        },
        changeTheme: (state) => {
            state.darkTheme = !state.darkTheme
        },
        changeHeight: (state) => {
            if (window.outerHeight > 1000) {
                state.height = window.outerHeight
            }
        },
        changeWidth: (state) => {
            if ( window.outerWidth > 576) {
                state.width = window.outerWidth
            }
        },
        setTheme: (state, action) => {
            if (action.payload === "dark") {
                state.darkTheme = true
                state.theme = "dark"
            } else if (action.payload === "white") {
                state.darkTheme = false
                state.theme = "white"
            }
        },
        setLanguage: (state, action) => {
            state.appLanguage = action.payload
            if (action.payload === "en") {
                state.appTextData =appDataText.en
                state.appFont = "Poppins"
            } else if (action.payload === "az") {
                state.appTextData =appDataText.az
            } else if (action.payload === "ru") {
                state.appTextData =appDataText.ru
                state.appFont = "Open Sans"
            } else {
                state.appTextData =appDataText.en
            }
        }
    }
});

export const { changeScreenSize, changeTheme, changeHeight, changeWidth, setTheme, setLanguage } = deviceInfoSlice.actions;
export default deviceInfoSlice.reducer