import {motion} from "framer-motion";
import {Image, theme, Typography} from "antd";
import phone_photo from "../../assets/images/app_main_screen.png";
import styles from "./appFeaturesComponent.module.css"
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Icon from "@ant-design/icons";
import {Waypoint} from "react-waypoint";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";

const AppFeaturesComponent = (props) => {
    const dispatch = useDispatch();

    const {Title, Text} = Typography
    const {isMobile, darkTheme, width, height, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)

    const TimeSvgDark = () => (
        <svg fill="#ff0000" height="3em" viewBox="-5 -6 32 32" id="Outline" xmlns="http://www.w3.org/2000/svg"
             stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="1"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"><title>194 restore</title>
                <path
                    d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z M23.812,10.132A12,12,0,0,0,3.578,3.415V1a1,1,0,0,0-2,0V5a2,2,0,0,0,2,2h4a1,1,0,0,0,0-2H4.827a9.99,9.99,0,1,1-2.835,7.878A.982.982,0,0,0,1,12a1.007,1.007,0,0,0-1,1.1,12,12,0,1,0,23.808-2.969Z"></path>
            </g>
        </svg>
    )

    const TimeSvgWhite = () => (
        <svg fill="#ff0000" height="3em" viewBox="-5 -6 32 32" id="Outline" xmlns="http://www.w3.org/2000/svg"
             stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="1"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"><title>194 restore</title>
                <path
                    d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z M23.812,10.132A12,12,0,0,0,3.578,3.415V1a1,1,0,0,0-2,0V5a2,2,0,0,0,2,2h4a1,1,0,0,0,0-2H4.827a9.99,9.99,0,1,1-2.835,7.878A.982.982,0,0,0,1,12a1.007,1.007,0,0,0-1,1.1,12,12,0,1,0,23.808-2.969Z"></path>
            </g>
        </svg>
    )

    const ReliabilitySvgWhite = () => (
        <svg viewBox="0 0 24 24" fill="none" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M10 21L7.18762 18.9912C4.55966 17.1141 3 14.0834 3 10.8538L3 5.75432C3 5.30784 3.29598 4.91546 3.72528 4.7928L9.72528 3.07852C9.90483 3.02721 10.0952 3.02721 10.2747 3.07852L16.2747 4.7928C16.704 4.91546 17 5.30784 17 5.75432V7.50002M19 15V13C19 11.8955 18.1046 11 17 11C15.8954 11 15 11.8955 15 13V15M19 15H15M19 15C20.1046 15 21 15.8955 21 17V19C21 20.1046 20.1046 21 19 21H15C13.8954 21 13 20.1046 13 19V17C13 15.8955 13.8954 15 15 15"
                    stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
        </svg>
    )

    const ReliabilitySvgDark = () => (
        <svg viewBox="0 0 24 24" fill="none" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M10 21L7.18762 18.9912C4.55966 17.1141 3 14.0834 3 10.8538L3 5.75432C3 5.30784 3.29598 4.91546 3.72528 4.7928L9.72528 3.07852C9.90483 3.02721 10.0952 3.02721 10.2747 3.07852L16.2747 4.7928C16.704 4.91546 17 5.30784 17 5.75432V7.50002M19 15V13C19 11.8955 18.1046 11 17 11C15.8954 11 15 11.8955 15 13V15M19 15H15M19 15C20.1046 15 21 15.8955 21 17V19C21 20.1046 20.1046 21 19 21H15C13.8954 21 13 20.1046 13 19V17C13 15.8955 13.8954 15 15 15"
                    stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
        </svg>
    )

    const EfficiencySvgWhite = () => (
        <svg fill="#ff0000" viewBox="0 0 64 64" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"><title></title>
                <g id="Productivity">
                    <path
                        d="M12,22H4a1,1,0,0,0-1,1V47a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V23A1,1,0,0,0,12,22ZM11,46H5V24h6Z"></path>
                    <path
                        d="M26,16H18a1,1,0,0,0-1,1V47a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V17A1,1,0,0,0,26,16ZM25,46H19V18h6Z"></path>
                    <path d="M41,31V27a1,1,0,0,0-1-1H32a1,1,0,0,0-1,1V47a1,1,0,0,0,1,1h6.25V46H33V28h6v3Z"></path>
                    <path d="M47,13h6V29.6h2V12a1,1,0,0,0-1-1H46a1,1,0,0,0-1,1V28.17h2Z"></path>
                    <path
                        d="M51,33A10,10,0,1,0,61,43,10,10,0,0,0,51,33Zm3.29,14.71-4-4A1,1,0,0,1,50,43V37h2v5.59l3.71,3.7Z"></path>
                </g>
            </g>
        </svg>
    )

    const EfficiencySvgDark = () => (
        <svg fill="#ff0000" viewBox="0 0 64 64" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"><title></title>
                <g id="Productivity">
                    <path
                        d="M12,22H4a1,1,0,0,0-1,1V47a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V23A1,1,0,0,0,12,22ZM11,46H5V24h6Z"></path>
                    <path
                        d="M26,16H18a1,1,0,0,0-1,1V47a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V17A1,1,0,0,0,26,16ZM25,46H19V18h6Z"></path>
                    <path d="M41,31V27a1,1,0,0,0-1-1H32a1,1,0,0,0-1,1V47a1,1,0,0,0,1,1h6.25V46H33V28h6v3Z"></path>
                    <path d="M47,13h6V29.6h2V12a1,1,0,0,0-1-1H46a1,1,0,0,0-1,1V28.17h2Z"></path>
                    <path
                        d="M51,33A10,10,0,1,0,61,43,10,10,0,0,0,51,33Zm3.29,14.71-4-4A1,1,0,0,1,50,43V37h2v5.59l3.71,3.7Z"></path>
                </g>
            </g>
        </svg>
    )

    const MobilitySvgDark = () => (
        <svg fill="#ff0000" viewBox="-10 0 46 46" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path id="_27.Phone" data-name="27.Phone"
                      d="M33,47H15a4,4,0,0,1-4-4V5a4,4,0,0,1,4-4H33a4,4,0,0,1,4,4V43A4,4,0,0,1,33,47ZM35,5a2,2,0,0,0-2-2H15a2,2,0,0,0-2,2V9H35Zm0,6H13V37H35Zm0,28H13v4a2,2,0,0,0,2,2H33a2,2,0,0,0,2-2ZM24,44a2,2,0,1,1,2-2A2,2,0,0,1,24,44ZM17,14h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V15A1,1,0,0,1,17,14Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V22A1,1,0,0,1,17,21Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V29A1,1,0,0,1,17,28Zm6-14h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H23a1,1,0,0,1-1-1V15A1,1,0,0,1,23,14Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H23a1,1,0,0,1-1-1V22A1,1,0,0,1,23,21Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H23a1,1,0,0,1-1-1V29A1,1,0,0,1,23,28Zm6-14h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H29a1,1,0,0,1-1-1V15A1,1,0,0,1,29,14Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H29a1,1,0,0,1-1-1V22A1,1,0,0,1,29,21ZM21,5h6a1,1,0,0,1,0,2H21a1,1,0,0,1,0-2Z"
                      transform="translate(-11 -1)" fill-rule="evenodd"></path>
            </g>
        </svg>
    )

    const MobilitySvgWhite = () => (
        <svg fill="#ff0000" viewBox="-10 0 46 46" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path id="_27.Phone" data-name="27.Phone"
                      d="M33,47H15a4,4,0,0,1-4-4V5a4,4,0,0,1,4-4H33a4,4,0,0,1,4,4V43A4,4,0,0,1,33,47ZM35,5a2,2,0,0,0-2-2H15a2,2,0,0,0-2,2V9H35Zm0,6H13V37H35Zm0,28H13v4a2,2,0,0,0,2,2H33a2,2,0,0,0,2-2ZM24,44a2,2,0,1,1,2-2A2,2,0,0,1,24,44ZM17,14h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V15A1,1,0,0,1,17,14Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V22A1,1,0,0,1,17,21Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V29A1,1,0,0,1,17,28Zm6-14h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H23a1,1,0,0,1-1-1V15A1,1,0,0,1,23,14Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H23a1,1,0,0,1-1-1V22A1,1,0,0,1,23,21Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H23a1,1,0,0,1-1-1V29A1,1,0,0,1,23,28Zm6-14h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H29a1,1,0,0,1-1-1V15A1,1,0,0,1,29,14Zm0,7h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H29a1,1,0,0,1-1-1V22A1,1,0,0,1,29,21ZM21,5h6a1,1,0,0,1,0,2H21a1,1,0,0,1,0-2Z"
                      transform="translate(-11 -1)" fill-rule="evenodd"></path>
            </g>
        </svg>
    )

    const FlexibilitySvgWhite = () => (
        <svg fill="#ff0000" viewBox="0 0 24 24" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M2,5A3,3,0,1,1,5.965,7.827.915.915,0,0,1,6,8v5a1,1,0,0,1-2,0V8a.915.915,0,0,1,.035-.173A2.989,2.989,0,0,1,2,5ZM19.965,16.173A.915.915,0,0,0,20,16V12a1,1,0,0,0-2,0v4a.915.915,0,0,0,.035.173,3,3,0,1,0,1.93,0ZM21,2H17a1,1,0,0,0-1,1V4H12a1,1,0,0,0,0,2h4V7a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM12,18H8V17a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1V20h4a1,1,0,0,0,0-2Z"></path>
            </g>
        </svg>
    )

    const FlexibilitySvgDark = () => (
        <svg fill="#ff0000" viewBox="0 0 24 24" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M2,5A3,3,0,1,1,5.965,7.827.915.915,0,0,1,6,8v5a1,1,0,0,1-2,0V8a.915.915,0,0,1,.035-.173A2.989,2.989,0,0,1,2,5ZM19.965,16.173A.915.915,0,0,0,20,16V12a1,1,0,0,0-2,0v4a.915.915,0,0,0,.035.173,3,3,0,1,0,1.93,0ZM21,2H17a1,1,0,0,0-1,1V4H12a1,1,0,0,0,0,2h4V7a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM12,18H8V17a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1V20h4a1,1,0,0,0,0-2Z"></path>
            </g>
        </svg>
    )

    const ComfortableSvgWhite = () => (
        <svg viewBox="0 2 20 20" fill="none" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M15.9957 11.5C14.8197 10.912 11.9957 9 10.4957 9C8.9957 9 5.17825 11.7674 6 13C7 14.5 9.15134 11.7256 10.4957 12C11.8401 12.2744 13 13.5 13 14.5C13 15.5 11.8401 16.939 10.4957 16.5C9.15134 16.061 8.58665 14.3415 7.4957 14C6.21272 13.5984 5.05843 14.6168 5.5 15.5C5.94157 16.3832 7.10688 17.6006 8.4957 19C9.74229 20.2561 11.9957 21.5 14.9957 20C17.9957 18.5 18.5 16.2498 18.5 13C18.5 11.5 13.7332 5.36875 11.9957 4.5C10.9957 4 10 5 10.9957 6.5C11.614 7.43149 13.5 9.27705 14 10.3751M15.5 8C15.5 8 15.3707 7.5 14.9957 6C14.4957 4 15.9957 3.5 16.4957 4.5C17.1281 5.76491 18.2872 10.9147 18.4957 13"
                    stroke="#ff0000" stroke-width="2"></path>
            </g>
        </svg>
    )

    const ComfortableSvgDark = () => (
        <svg viewBox="0 0 25 25" fill="none" height="3em" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M15.9957 11.5C14.8197 10.912 11.9957 9 10.4957 9C8.9957 9 5.17825 11.7674 6 13C7 14.5 9.15134 11.7256 10.4957 12C11.8401 12.2744 13 13.5 13 14.5C13 15.5 11.8401 16.939 10.4957 16.5C9.15134 16.061 8.58665 14.3415 7.4957 14C6.21272 13.5984 5.05843 14.6168 5.5 15.5C5.94157 16.3832 7.10688 17.6006 8.4957 19C9.74229 20.2561 11.9957 21.5 14.9957 20C17.9957 18.5 18.5 16.2498 18.5 13C18.5 11.5 13.7332 5.36875 11.9957 4.5C10.9957 4 10 5 10.9957 6.5C11.614 7.43149 13.5 9.27705 14 10.3751M15.5 8C15.5 8 15.3707 7.5 14.9957 6C14.4957 4 15.9957 3.5 16.4957 4.5C17.1281 5.76491 18.2872 10.9147 18.4957 13"
                    stroke="#ff0000" stroke-width="2"></path>
            </g>
        </svg>
    )

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const changeNav = (args) => {
        dispatch(changeNavigationLink(["Features"]))
        // console.log('Вошли на компонент', args);
    };

    return (
        <div className={styles.content} style={{
            minHeight: height,
            position: "relative",
            width: "100%",
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                minHeight: height * 0.4,
                width: "100%"
            }}>
                <div id={"Features"} style={{textAlign: "center"}}>
                    <Waypoint onEnter={changeNav}
                        // onLeave={handleLeave}
                    >
                        <div style={{height: 42}}></div>
                    </Waypoint>
                    <Title style={{fontSize: 36, fontFamily: appFont}}>{appTextData.AppFeatures.title}</Title>
                </div>
                <div className={styles.textInfo}>
                    <Text style={{fontSize: 16, fontFamily: appFont}}>{appTextData.AppFeatures.titleInfo}</Text>
                </div>
            </div>
            <div style={{
                width: "100%",
                minHeight: height * 0.6,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
            }}>
                <div style={isMobile ? {width: "100%", height: height * 0.7} : {
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <div className={styles.left_box}>
                        <Icon component={darkTheme ? ComfortableSvgDark : ComfortableSvgWhite}/>
                        <Text style={{fontSize: 24, fontWeight: "bold", fontFamily: appFont}}>{appTextData.AppFeatures.convenienceTitle}</Text>
                        <Text style={{fontFamily: appFont}}>{appTextData.AppFeatures.convenienceText}</Text>
                    </div>
                    <div className={styles.left_box}>
                        <Icon component={darkTheme ? EfficiencySvgDark : EfficiencySvgWhite}/>
                        <Text style={{fontSize: 24, fontWeight: "bold", fontFamily: appFont}}>{appTextData.AppFeatures.efficiencyTitle}</Text>
                        <Text style={{fontFamily: appFont}}>{appTextData.AppFeatures.efficiencyText}</Text>
                    </div>
                    <div className={styles.left_box}>
                        <Icon component={darkTheme ? FlexibilitySvgDark : FlexibilitySvgWhite}/>
                        <Text style={{fontSize: 24, fontWeight: "bold", fontFamily: appFont}}>{appTextData.AppFeatures.flexibilityTitle}</Text>
                        <Text style={{fontFamily: appFont}}>{appTextData.AppFeatures.flexibilityText}</Text>
                    </div>
                </div>
                <motion.div
                    style={isMobile ? {width: "100%", height: height} : {width: "40%"}}
                    initial={{y: 100, opacity: 0}} // начальное состояние компонента (смещение вниз и прозрачность 0)
                    whileInView={{
                        y: 0,
                        opacity: 1
                    }} // конечное состояние компонента (нет смещения и полная прозрачность)
                    transition={{duration: 1}} // длительность анимации
                    viewport={{once: false}}
                >
                    <div style={{
                        height: "100%",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundImage: `url(${phone_photo})`,
                        width: isMobile ? "100%" : "auto",
                    }}></div>
                </motion.div>
                <div style={isMobile ? {width: "100%", height: height * 0.7} : {
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <div className={styles.right_box}>
                        <Icon component={darkTheme ? TimeSvgDark : TimeSvgWhite}/>
                        <Text style={{fontSize: 24, fontWeight: "bold", fontFamily: appFont}}>{appTextData.AppFeatures.realTimeTitle}</Text>
                        <Text style={{fontFamily: appFont}}>{appTextData.AppFeatures.realTimeText}</Text>
                    </div>
                    <div className={styles.right_box}>
                        <Icon component={darkTheme ? MobilitySvgDark : MobilitySvgWhite}/>
                        <Text style={{fontSize: 24, fontWeight: "bold", fontFamily: appFont}}>{appTextData.AppFeatures.mobilityTitle}</Text>
                        <Text style={{fontFamily: appFont}}>{appTextData.AppFeatures.mobilityText}</Text>
                    </div>
                    <div className={styles.right_box}>
                        <Icon component={darkTheme ? ReliabilitySvgDark : ReliabilitySvgWhite}/>
                        <Text style={{fontSize: 24, fontWeight: "bold", fontFamily: appFont}}>{appTextData.AppFeatures.reliabilityTitle}</Text>
                        <Text style={{fontFamily: appFont}}>{appTextData.AppFeatures.reliabilityText}</Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppFeaturesComponent