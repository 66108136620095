import styles from "./loginform.module.css"
import {useState} from "react";
import {Form, Input, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {FacebookOutlined, GoogleOutlined, InstagramOutlined} from "@ant-design/icons";
import {loginApi} from "../../../store/reducers/Actions";
import {changeLoginModalOpen} from "../../../store/reducers/userSlice";

const LoginComponent = () => {
    const [loginPage, setLoginPage] = useState(false)

    const {Title, Text} = Typography
    const {darkTheme, appLanguage, appFont, appTextData, theme} = useSelector(state => state.deviceInfoSlice)
    const dispatch = useDispatch();


    return (
        <div className={`${styles.container} ${loginPage ? styles.rightPanelActive : ""}`} id="container">
            <div className={`${styles.form_container} ${styles.sign_up_container}`}>
                <Form className={styles.loginForm} name="register">
                    <Title style={{fontFamily: appFont}} level={2} className={styles.titleWhite}>Create Account</Title>
                    <div className={styles.social_container}>
                        <a href="#" className={styles.social}><FacebookOutlined
                            style={darkTheme ? {fontSize: 18, color: "white"} : {fontSize: 18, color: "black"}}/></a>
                        <a href="#" className={styles.social}><GoogleOutlined
                            style={darkTheme ? {fontSize: 18, color: "white"} : {fontSize: 18, color: "black"}}/></a>
                        <a href="#" className={styles.social}><InstagramOutlined
                            style={darkTheme ? {fontSize: 18, color: "white"} : {fontSize: 18, color: "black"}}/></a>
                    </div>
                    <Text style={darkTheme ? {color: "white", fontSize: 12} : {color: "black", fontSize: 12}}>or use
                        your email for registration</Text>
                    <Form.Item
                        style={{width: "100%", marginBottom: 8, marginTop: 8}}
                        name="username"
                        rules={[{required: true, message: 'Please input your username!'}]}
                    >
                        <Input className={styles.loginInput} type="text" placeholder="Name"/>
                    </Form.Item>
                    <Form.Item
                        style={{width: "100%", marginBottom: 8, marginTop: 8}}
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input className={styles.loginInput} type="email" placeholder="Email"/>
                    </Form.Item>
                    <Form.Item
                        style={{width: "100%", marginBottom: 8, marginTop: 8}}
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password className={styles.loginInput} type="password" placeholder="Password"/>
                    </Form.Item>
                    <Form.Item>
                        <button className={styles.buttonLogin}>Sign Up</button>
                    </Form.Item>
                </Form>
            </div>
            <div className={`${styles.form_container} ${styles.sign_in_container}`}>
                <Form className={styles.loginForm} name="login" onFinish={async (e) => {
                    await dispatch(loginApi({username: e.username, password: e.password}))
                    console.log(e)
                }}>
                    <Title style={{fontFamily: appFont}} level={2} className={styles.titleWhite}>Sign in</Title>
                    <div className={styles.social_container}>
                        <a href="#" className={styles.social}><FacebookOutlined
                            style={darkTheme ? {fontSize: 18, color: "white"} : {fontSize: 18, color: "black"}}/></a>
                        <a href="#" className={styles.social}><GoogleOutlined
                            style={darkTheme ? {fontSize: 18, color: "white"} : {fontSize: 18, color: "black"}}/></a>
                        <a href="#" className={styles.social}><InstagramOutlined
                            style={darkTheme ? {fontSize: 18, color: "white"} : {fontSize: 18, color: "black"}}/></a>
                    </div>
                    <Text style={darkTheme ? {color: "white", fontSize: 12} : {color: "black", fontSize: 12}}>or use
                        your account</Text>
                    <Form.Item
                        style={{width: "100%", marginBottom: 8, marginTop: 8}}
                        name="username"
                        rules={[{required: true, message: 'Please input your username!'}]}
                    >
                        <Input className={styles.loginInput} type="email" placeholder="Email"/>
                    </Form.Item>
                    <Form.Item
                        style={{width: "100%", marginBottom: 8, marginTop: 8}}
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password className={styles.loginInput} type="password" placeholder="Password"/>
                    </Form.Item>
                    <Form.Item>
                        <button className={styles.buttonLogin}>
                            Sign In
                        </button>
                    </Form.Item>
                    {/*<a className={styles.social} href="#">Forgot your password?</a>*/}
                </Form>
            </div>
            <div className={styles.overlay_container}>
                <div className={styles.overlay}>
                    <div className={`${styles.overlay_panel} ${styles.overlay_left}`}>
                        <h1 style={{fontFamily: appFont}} className={styles.titleBlack}>Welcome Back!</h1>
                        <Text style={{fontFamily: appFont}} className={styles.titleInfo}>To keep connected with us
                            please login with your personal info</Text>
                        <button onClick={() => setLoginPage(!loginPage)}
                                className={`${styles.buttonLogin} ${styles.ghost}`} id="signIn">Sign In
                        </button>
                    </div>
                    <div className={`${styles.overlay_panel} ${styles.overlay_right}`}>
                        <h1 style={{fontFamily: appFont}} className={styles.titleBlack}>Hello, Friend!</h1>
                        <Text style={{fontFamily: appFont}} className={styles.titleInfo}>Enter your personal details and
                            start journey with us</Text>
                        <button onClick={() => setLoginPage(!loginPage)}
                                className={`${styles.buttonLogin} ${styles.ghost}`} id="signUp">Sign Up
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginComponent