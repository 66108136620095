export const routes = {
    home: '/',
    dashboard: "/dashboard",

    welcome: '/main-page',
    group: '/group/:group_id/',
    main1: "/main1",
    main2: "/main2",
    login: '/login',
    registration: '/registration',
    notFound: '*',
};