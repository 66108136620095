import {motion} from "framer-motion";
import {
    Avatar,
    Button,
    Checkbox,
    Col,
    Form,
    Image,
    Input,
    notification,
    Row,
    Select,
    theme, TimePicker,
    Tooltip,
    Typography
} from "antd";
import phone_photo from "../../assets/images/home-app-10.png";
import styles from "./appContactComponent.css"
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Waypoint} from "react-waypoint";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    FacebookOutlined,
    LinkedinOutlined,
    LoadingOutlined
} from "@ant-design/icons";
import SliderCaptcha from "rc-slider-captcha";
import createPuzzle from 'create-puzzle';
import {randomInt} from "ut2";
import city from "../../assets/images/captcha/city.jpg"
import mountain from "../../assets/images/captcha/mountain.jpeg"
import sea from "../../assets/images/captcha/sea.jpg"
import {
    changeCaptchaError,
    changeCaptchaSuccess,
    changeCaptchaVisibility,
    changeUserType,
    changeProduct
} from "../../store/reducers/contactSlice";
import * as emailjs from "emailjs-com";

const AppContactComponent = (props) => {
    const dispatch = useDispatch();
    const {Title, Text} = Typography
    const { RangePicker } = TimePicker
    const {isMobile, height, width, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)
    const {
        product,
        isCaptchaShow,
        isCaptchaError,
        isCaptchaSuccess,
        userType
    } = useSelector(state => state.contactSlice)
    const {Option} = Select;

    const offsetXRef = useRef(0);
    const captcha_photo = [city, mountain, sea]
    const [form] = Form.useForm();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);


    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const sendEmail = (values) => {
        const formElement = document.createElement('form');

        // Добавляем скрытое поле для каждого свойства объекта
        Object.entries(values).forEach(([name, value]) => {
            const inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = name;
            inputElement.value = value;
            formElement.appendChild(inputElement);
        });

        const inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "register_type";
        inputElement.value = "simple user";
        formElement.appendChild(inputElement);

        // Добавляем форму на страницу, чтобы сработал метод sendForm
        formElement.style.display = 'none';
        document.body.appendChild(formElement);

        emailjs.sendForm('service_m8usqk5', 'template_15b7rln', formElement , '8w2jjjPt6B6-jVFY9')
            .then((result) => {
                setIsEmailSent(true);
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        // Удаляем временную форму сразу после отправки
        document.body.removeChild(formElement);
    };

    useEffect(() => {
        // Если письмо успешно отправлено (флаг установлен в true), показываем уведомление
        if (isEmailSent) {
            notification.success({
                message: "Success",
                description: "Your form has been sent successfully. We will call you close time!",
            });
            setIsEmailSent(false)
        }
    }, [isEmailSent]);

    useEffect(() => {
        // Если письмо успешно отправлено (флаг установлен в true), показываем уведомление
        if (isEmailError) {
            notification.error({
                message: "Error",
                description: "Error when sending your form",
            });
            setIsEmailError(false)
        }
    }, [isEmailError]);

    const changeNav = (args) => {
        dispatch(changeNavigationLink(["Contact"]))
        // console.log('Вошли на компонент', args);
    };

    const onFinish = (values) => {
        sendEmail(values)
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.setFieldsValue({usertype: userType})
    }, [userType])

    useEffect(() => {
        form.setFieldsValue({product: product})
    }, [product])

    return (
        <div id={"Contact"} style={{display: "flex", minHeight: height, width: "100%", flexWrap: "wrap", alignItems: "center"}}>
            <div style={isMobile ? {
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center"
            } : {display: "flex", width: "50%", flexWrap: "wrap", flexDirection: "column"}}>
                <Waypoint onEnter={changeNav}
                    // onLeave={handleLeave}
                >
                    <Title style={isMobile ? {fontSize: 36, fontFamily: appFont} : {fontSize: 56, fontFamily: appFont}}>{appTextData.AppContact.title}</Title>
                </Waypoint>
                <div style={{paddingBottom: 20}}>
                    <a>
                        <FacebookOutlined style={{color: "red", fontSize: 32, paddingRight: 10}}/>
                    </a>
                    <a>
                        <LinkedinOutlined style={{color: "red", fontSize: 32}}/>
                    </a>
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "50%"}}>
                    <Text style={{fontFamily: appFont}}>{appTextData.AppContact.text}</Text>
                </div>
            </div>
            <div style={isMobile ? {
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                alignItems: "center",
                flexDirection: "column",
                overflowX: "hidden"
            } : {display: "flex", width: "50%", flexWrap: "wrap", alignItems: "center", flexDirection: "column", marginTop: 20, border: "1px solid red", paddingTop: 20, borderRadius: 20}}>
                <Form
                    form={form}
                    name="contact_form"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 600}}
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label={appTextData.AppContact.name}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={appTextData.AppContact.phone}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                            },
                        ]}
                    >
                        <Input
                            addonBefore={<Select
                                style={{
                                    width: 80,
                                }}
                            >
                                <Option value="...">...</Option>
                                <Option value="+7">+7</Option>
                                <Option value="+380">+380</Option>
                                <Option value="+994">+994</Option>
                            </Select>}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'email'}
                        label={"Email"}
                        rules={[
                            {
                                type: 'email',
                                required: true,
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="product"
                        label={appTextData.AppContact.product}
                        rules={[
                            {
                                required: true,
                                message: 'Please select product (subscription)!',
                            },
                        ]}
                    >
                        <Select placeholder={appTextData.AppContact.productPlaceHolder}>
                            <Option value="basic">{appTextData.AppPricing.basic}</Option>
                            <Option value="pro">{appTextData.AppPricing.professional}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="time" label={appTextData.AppContact.time}>
                        <RangePicker showTime format="HH:mm" />
                    </Form.Item>
                    <Form.Item
                        name="additional"
                        label={appTextData.AppContact.additionalInfo}
                        tooltip={appTextData.AppContact.additionalText}
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input.TextArea showCount maxLength={100}/>
                    </Form.Item>
                    <Form.Item
                        name="capthca"
                        label="Captcha" extra={appTextData.AppContact.captchaText}
                        rules={[
                            {
                                validator: (_, value) =>
                                    isCaptchaSuccess ? Promise.resolve() : Promise.reject(new Error('Incorrect captcha')),
                            },
                        ]}>
                        <Row gutter={8}>
                            <Col span={12}>
                                <Button onClick={() => {
                                    if (isCaptchaShow === false && isCaptchaSuccess === false) {
                                        dispatch(changeCaptchaVisibility())
                                    }
                                }}>Get captcha</Button>
                            </Col>
                            {isCaptchaSuccess && <CheckCircleOutlined style={{fontSize: 20}}/>}
                            {isCaptchaError && <CloseCircleOutlined style={{fontSize: 20}}/>}
                        </Row>
                    </Form.Item>
                    {isCaptchaShow && !isCaptchaSuccess && <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <SliderCaptcha
                            request={() =>
                                createPuzzle(captcha_photo[Math.floor(Math.random() * captcha_photo.length)], {
                                    x: randomInt(80, 300)
                                }).then((res) => {
                                    offsetXRef.current = res.x;

                                    return {
                                        bgUrl: res.bgUrl,
                                        puzzleUrl: res.puzzleUrl
                                    };
                                })
                            }
                            tipText={{
                                default: 'Drag to the right to complete the puzzle',
                                loading: 'Loading...',
                                moving: 'Drag to the right to the puzzle position'
                            }}
                            loadingBoxProps={{
                                icon: <LoadingOutlined/>,
                                text: "loading"
                            }}
                            onVerify={async (data) => {
                                // console.log(data);
                                if (data.x >= offsetXRef.current - 5 && data.x < offsetXRef.current + 5) {
                                    setTimeout(() => {
                                        dispatch(changeCaptchaSuccess())
                                    }, 1500)
                                    return Promise.resolve();
                                } else {
                                    dispatch(changeCaptchaError())
                                    return Promise.reject();
                                }
                            }}
                            bgSize={{
                                width: 360
                            }}
                            loadingDelay={300}
                        />
                    </div>}
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                            },
                        ]}
                    >
                        <Checkbox>
                            {appTextData.AppContact.agreements}<a href="">{appTextData.AppContact.agreementWord}</a>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            {appTextData.AppContact.submit}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default AppContactComponent