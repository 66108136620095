import {motion} from "framer-motion";
import {Avatar, Button, Image, theme, Tooltip, Typography} from "antd";
import styles from "./appPricingComponent.module.css"
import "./style.css"
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Waypoint} from "react-waypoint";
import {changeNavigationLink} from "../../store/reducers/navigationSlice";
import {CheckCircleOutlined, CloseCircleOutlined, DownOutlined} from "@ant-design/icons";

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import {Pagination} from 'swiper/modules';
import {changeProduct} from "../../store/reducers/contactSlice";


const AppPricingComponent = (props) => {
    const dispatch = useDispatch();
    const {Title, Text} = Typography
    const {isMobile, darkTheme, width, height, appTextData, appFont} = useSelector(state => state.deviceInfoSlice)

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const changeNav = (args) => {
        dispatch(changeNavigationLink(["Pricing"]))
        // console.log('Вошли на компонент', args);
    };

    return (
        <div id={"Pricing"} className={styles.content}
             style={isMobile ? {minHeight: height } : {minHeight: height + 64}}>
            {darkTheme ? (
                <style>
                    {`.swiper-pagination-bullet {
                        background: rgb(200, 200, 200) !important;
                    }
                    .swiper-pagination-bullet-active {
                        background: red !important;
                    }`}
                </style>
            ) : (
                <style>
                    {`.swiper-pagination-bullet {
                        background: rgb(5, 5, 5) !important;
                    }
                    .swiper-pagination-bullet-active {
                        background: red !important;
                    }`}
                </style>
            )}
            <div className={"swiperBox"}>
                <Waypoint onEnter={changeNav}
                    // onLeave={handleLeave}
                >
                    <div id={"Pricing"} style={isMobile ? {display: "flex", width: "100%", minHeight: "10%", textAlign: "center", alignItems: "center", justifyContent: "center", flexDirection: "column"} : {display: "flex", width: "100%", minHeight: "10%", textAlign: "center", alignItems: "center", justifyContent: "center", flexDirection: "column", paddingTop: 64, paddingBottom: 32}}>
                        <Text style={{fontFamily: appFont, fontWeight: "500", color: "red", fontSize: 18}}>{appTextData.AppPricing.pricing}</Text>
                        <Text style={{fontFamily: appFont, fontWeight: "600", fontSize: 36}}>{appTextData.AppPricing.title}</Text>
                        <div style={isMobile? {maxWidth: "90%"} : {maxWidth: "60%"}}>
                            <Text style={{fontFamily: appFont}}>{appTextData.AppPricing.titleText}</Text>
                        </div>
                    </div>
                </Waypoint>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 100,
                        },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide style={{backgroundColor: "transparent", display: "flex", alignItems: "center"}}>
                        <div style={{display: "flex", minHeight: "70%", width: "85%", backgroundColor: "transparent", padding: "20px", flexDirection: "column", alignItems: "flex-start", borderRadius: 20, justifyContent: "space-around", border: "1px solid red", marginBottom: 20}}>
                            <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
                                <Text style={{fontFamily: appFont, fontSize: 24, color: "red"}}>{appTextData.AppPricing.basic}</Text>
                                <div>
                                    <Text style={{fontFamily: appFont, fontSize: 36, fontWeight: "bold"}}>₼250</Text><Tooltip placement={"right"} title={appTextData.AppPricing.priceInfo}><Text>{appTextData.AppPricing.priceSecond}</Text></Tooltip>
                                </div>
                                <Text style={{textAlign: "left", fontFamily: appFont}}>{appTextData.AppPricing.basicInfo}</Text>
                                <div style={{paddingTop: 20, paddingBottom: 20}}>
                                    <Button href={"#Contact"} size={"large"} shape="round" style={{minWidth: 200}} type="primary" onClick={() => {
                                        dispatch(changeProduct("basic"))
                                        dispatch(changeNavigationLink(["Contact"]))
                                    }}><Text
                                        style={{fontFamily: appFont}}>{appTextData.AppPricing.startBasic}</Text></Button>
                                </div>
                            </div>
                            <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
                                <Text style={{textAlign: "left", fontFamily: appFont, fontWeight: "500"}}>{appTextData.AppPricing.miniTitle}</Text>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function1}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function2}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function3}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function4}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CloseCircleOutlined style={{color: "rgb(155,155,155)", paddingRight: 10}}/>
                                    <Text style={{color: "rgb(155,155,155)"}}>{appTextData.AppPricing.function5}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CloseCircleOutlined style={{color: "rgb(155,155,155)", paddingRight: 10}}/>
                                    <Text style={{color: "rgb(155,155,155)"}}>{appTextData.AppPricing.function6}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CloseCircleOutlined style={{color: "rgb(155,155,155)", paddingRight: 10}}/>
                                    <Text style={{color: "rgb(155,155,155)"}}>{appTextData.AppPricing.function7}</Text>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{backgroundColor: "transparent", display: "flex", alignItems: "center"}}>
                        <div style={{display: "flex", minHeight: "70%", width: "85%", backgroundColor: "transparent", padding: "20px", flexDirection: "column", alignItems: "flex-start", borderRadius: 20, justifyContent: "space-around", border: "1px solid red", marginBottom: 20}}>
                            <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
                                <Text style={{fontFamily: appFont, fontSize: 24, color: "red"}}>{appTextData.AppPricing.professional}</Text>
                                <div>
                                    <Text style={{fontFamily: appFont, fontSize: 36, fontWeight: "bold"}}>₼350</Text><Tooltip placement={"right"} title={appTextData.AppPricing.priceInfo}><Text>{appTextData.AppPricing.priceSecond}</Text></Tooltip>
                                </div>
                                <Text style={{textAlign: "left", fontFamily: appFont}}>{appTextData.AppPricing.professionalInfo}</Text>
                                <div style={{paddingTop: 20, paddingBottom: 20}}>
                                    <Button href={"#Contact"} size={"large"} shape="round" style={{minWidth: 200}} type="primary" onClick={() => {
                                        dispatch(changeProduct("pro"))
                                        dispatch(changeNavigationLink(["Contact"]))
                                    }}><Text
                                        style={{fontFamily: appFont}}>{appTextData.AppPricing.startProfessional}</Text></Button>
                                </div>
                            </div>
                            <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-evenly"}}>
                                <Text style={{textAlign: "left", fontFamily: appFont, fontWeight: "500"}}>{appTextData.AppPricing.miniTitle}</Text>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function1}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function2}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function3}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function4}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function5}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function6}</Text>
                                </div>
                                <div style={{textAlign: "left"}}>
                                    <CheckCircleOutlined style={{color: "red", paddingRight: 10}}/>
                                    <Text>{appTextData.AppPricing.function7}</Text>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default AppPricingComponent